import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import "./Pdf.css";
import { Dark } from "../../../../../Theme/Theme";
import Menu from "./Menu";
import HoldingCard from "./HoldingCard";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#000",
    display: "flex",
  },
  sectionheader: {
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Dark.primary,
  },
  sectionfooter: {
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Dark.secondary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  section1: {
    margin: 10,
    padding: 10,
    backgroundColor: Dark.secondary,
    borderRadius: 10,
  },
});

// Create Document Component
const Pdf = (props) => {
  const socket = props.socket;

  let futurebuy = parseFloat(
    props.futurebuy == null ? 0 : props.futurebuy
  ).toFixed(0);
  let futuresell = parseFloat(
    props.futuresell == null ? 0 : props.futuresell
  ).toFixed(0);
  let future = parseFloat(props.future == null ? 0 : props.future).toFixed(0);

  let mcxbuy = parseFloat(props.mcxbuy == null ? 0 : props.mcxbuy).toFixed(0);
  let mcxsell = parseFloat(props.mcxsell == null ? 0 : props.mcxsell).toFixed(
    0
  );
  let mcx = parseFloat(props.mcx == null ? 0 : props.mcx).toFixed(0);

  let optionsbuy = parseFloat(
    props.optionsbuy == null ? 0 : props.optionsbuy
  ).toFixed(0);
  let optionssell = parseFloat(
    props.optionssell == null ? 0 : props.optionssell
  ).toFixed(0);
  let options = parseFloat(props.options == null ? 0 : props.options).toFixed(
    0
  );

  let buy = parseFloat(props.buy == null ? 0 : props.buy).toFixed(0);
  let sell = parseFloat(props.sell == null ? 0 : props.sell).toFixed(0);
  let totalvol = parseFloat(props.totalvol == null ? 0 : props.totalvol).toFixed(0);

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View fixed style={styles.sectionheader}>
          <View style={{ flex: 1, paddingLeft: 20 }}>
            <Text style={{ fontSize: 16, color: Dark.text }}>Holding</Text>
          </View>

  
          <View
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: Dark.background,
              borderRadius: 10,
              marginRight: 10,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: props.total > 0 ? Dark.buy : Dark.sell,
              }}
            >
              M2M : {parseFloat(props.total).toFixed(2)}
            </Text>
          </View>
        </View>

        <Menu />
        {props.holding.length > 0
          ? props.holding.map((i) => {
              return <HoldingCard item={i} socket={socket} />;
            })
          : null}

        <View
          style={{
            height: 205,
            backgroundColor: Dark.background,
          }}
        >
          <View
            style={{
              height: 50,
              backgroundColor: Dark.primary,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: 35,
                  width: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Dark.secondary,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    color: Dark.text,
                    fontSize: 12,
                  }}
                >
                  NSE
                </Text>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: 35,
                  width: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Dark.secondary,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    color: Dark.text,
                    fontSize: 12,
                  }}
                >
                  MCX
                </Text>
              </View>
            </View>
            <View
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: 35,
                  width: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Dark.secondary,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    color: Dark.text,
                    fontSize: 12,
                  }}
                >
                  OPTIONS
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              height: 100,
              backgroundColor: Dark.primary,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: 80,
                  width: 200,
                  backgroundColor: Dark.secondary,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: Dark.text,
                      fontSize: 12,
                      flex: 1,
                      paddingLeft: 10,
                    }}
                  >
                    TOTAL
                  </Text>
                  <Text
                    style={{
                      color: Dark.text,
                      fontSize: 12,
                      paddingRight: 10,
                    }}
                  >
                    {parseFloat(future).toFixed(0)}
                  </Text>
                </View>
                <View
                  style={{
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: Dark.buy,
                      fontSize: 12,
                      flex: 1,
                      paddingLeft: 10,
                    }}
                  >
                    BUY
                  </Text>
                  <Text
                    style={{
                      color: Dark.buy,
                      fontSize: 12,
                      paddingRight: 10,
                    }}
                  >
                    {parseFloat(futurebuy).toFixed(0)}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: Dark.sell,
                      fontSize: 12,
                      flex: 1,
                      paddingLeft: 10,
                    }}
                  >
                    SELL
                  </Text>
                  <Text
                    style={{
                      color: Dark.sell,
                      fontSize: 12,
                      paddingRight: 10,
                    }}
                  >
                    {parseFloat(futuresell).toFixed(0)}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: 80,
                  width: 200,
                  backgroundColor: Dark.secondary,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: Dark.text,
                      fontSize: 12,
                      flex: 1,
                      paddingLeft: 10,
                    }}
                  >
                    TOTAL
                  </Text>
                  <Text
                    style={{
                      color: Dark.text,
                      fontSize: 12,
                      paddingRight: 10,
                    }}
                  >
                    {parseFloat(mcx).toFixed(0)}
                  </Text>
                </View>
                <View
                  style={{
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: Dark.buy,
                      fontSize: 12,
                      flex: 1,
                      paddingLeft: 10,
                    }}
                  >
                    BUY
                  </Text>
                  <Text
                    style={{
                      color: Dark.buy,
                      fontSize: 12,
                      paddingRight: 10,
                    }}
                  >
                    {parseFloat(mcxbuy).toFixed(0)}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: Dark.sell,
                      fontSize: 12,
                      flex: 1,
                      paddingLeft: 10,
                    }}
                  >
                    SELL
                  </Text>
                  <Text
                    style={{
                      color: Dark.sell,
                      fontSize: 12,
                      paddingRight: 10,
                    }}
                  >
                    {parseFloat(mcxsell).toFixed(0)}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: 80,
                  width: 200,
                  backgroundColor: Dark.secondary,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: Dark.text,
                      fontSize: 12,
                      flex: 1,
                      paddingLeft: 10,
                    }}
                  >
                    TOTAL
                  </Text>
                  <Text
                    style={{
                      color: Dark.text,
                      fontSize: 12,
                      paddingRight: 10,
                    }}
                  >
                    {parseFloat(options).toFixed(0)}{" "}
                  </Text>
                </View>
                <View
                  style={{
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: Dark.text,
                      fontSize: 12,
                      flex: 1,
                      paddingLeft: 10,
                    }}
                  >
                    BUY
                  </Text>
                  <Text
                    style={{
                      color: Dark.buy,
                      fontSize: 12,
                      paddingRight: 10,
                    }}
                  >
                    {parseFloat(optionsbuy).toFixed(0)}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      color: Dark.text,
                      fontSize: 12,
                      flex: 1,
                      paddingLeft: 10,
                    }}
                  >
                    SELL
                  </Text>
                  <Text
                    style={{
                      color: Dark.sell,
                      fontSize: 12,
                      paddingRight: 10,
                    }}
                  >
                    {parseFloat(optionssell).toFixed(0)}{" "}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.sectionfooter}>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: Dark.buy, fontSize: 12 }}>BUY</Text>
              <Text style={{ marginTop: 5, fontSize: 16, color: Dark.buy }}>
                {parseFloat(buy).toFixed(0)}
              </Text>
            </View>

            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: Dark.text, fontSize: 12 }}>TOTAL</Text>
              <Text
                style={{
                  marginTop: 5,
                  fontSize: 16,
                  color: Dark.text,
                }}
              >
                {parseFloat(totalvol).toFixed(0)}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: Dark.sell, fontSize: 12 }}>SELL</Text>
              <Text
                style={{
                  marginTop: 5,
                  fontSize: 16,
                  color: Dark.sell,
                }}
              >
                {parseFloat(sell).toFixed(0)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Pdf;
