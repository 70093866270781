import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import Menu from "./Menu";
import SummaryCard from "./SummaryCard";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Pdf from "./PdfS/Pdf";
import { InputText } from "primereact/inputtext";
import { IoSearch } from "react-icons/io5";

const backend = new Backend();
export default function Summary() {
  const [key, setKey] = useState(false);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");

  const [summary, setSummary] = useState([]);
  const [summarysearch, setSummarySearch] = useState([]);

  useEffect(() => {
    LoadSummary();
  }, []);

  const LoadSummary = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_summary(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setSummary(r.summary);
        setSummarySearch(r.summary);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const sort = (s) => {
    setKey(!key);

    let data;

    if (s == "ClientUp") {
      data = summarysearch.sort((a, b) => (a.username > b.username ? 1 : -1));
    } else if (s == "ClientDown") {
      data = summarysearch.sort((a, b) => (a.username < b.username ? 1 : -1));
    } else if (s == "TodayUp") {
      data = summarysearch.sort((a, b) =>
        a.profit_loss_day > b.profit_loss_day ? 1 : -1
      );
    } else if (s == "TodayDown") {
      data = summarysearch.sort((a, b) =>
        a.profit_loss_day < b.profit_loss_day ? 1 : -1
      );
    } else if (s == "WeekUp") {
      data = summarysearch.sort((a, b) =>
        a.profit_loss_week > b.profit_loss_week ? 1 : -1
      );
    } else if (s == "WeekDown") {
      data = summarysearch.sort((a, b) =>
        a.profit_loss_week < b.profit_loss_week ? 1 : -1
      );
    } else if (s == "NetUp") {
      data = summarysearch.sort((a, b) =>
        a.deposit_total > b.deposit_total ? 1 : -1
      );
    } else if (s == "NetDown") {
      data = summarysearch.sort((a, b) =>
        a.deposit_total < b.deposit_total ? 1 : -1
      );
    } else if (s == "CreditUp") {
      data = summarysearch.sort((a, b) =>
        a.deposit_current > b.deposit_current ? 1 : -1
      );
    } else if (s == "CreditDown") {
      data = summarysearch.sort((a, b) =>
        a.deposit_current < b.deposit_current ? 1 : -1
      );
    } else {
      setSummary([]);
    }

    setSummary(data);
  };

  const searchSymbol = (a) => {
    if (a.length > 1) {
      var data = summarysearch.filter((obj) =>
        Object.keys(obj).some((key) =>
          obj[key].toString().toLowerCase().includes(a.toString().toLowerCase())
        )
      );
      setSummary(data);
    } else {
      setSummary(summarysearch);
    }
  };

  return (
    <div
      style={{
        height: "78vh",
        backgroundColor: Dark.background,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          color: Dark.text,
          padding: 10,
          backgroundColor: Dark.primary,
          width: "100%",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "uppercase",
        }}
      >
        <div style={{ flex: 1, paddingLeft: 20 }}>Account Summary</div>
        {summary.length > 0 ? (
          <div
            style={{
              paddingRight: 20,
              backgroundColor: Dark.secondary,
              paddingLeft: 20,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginRight: 20,
            }}
          >
            <PDFDownloadLink
              style={{
                textDecoration: "none",
              }}
              document={<Pdf data={summary} />}
              fileName={"Summary-List"}
            >
              {({ blob, url, loading, error }) => (
                <div style={{ color: Dark.text }}>DOWNLOAD PDF</div>
              )}
            </PDFDownloadLink>
          </div>
        ) : null}
        <div
          style={{
            marginRight: 10,
            width: "250px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IoSearch style={{ color: Dark.text, fontSize: 22 }} />
          </div>
          <div>
            <InputText
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                searchSymbol(e.target.value);
              }}
              style={{
                height: 40,
                backgroundColor: Dark.background,
                background: Dark.background,
                borderStyle: "none",
                width: "180px",
                color: Dark.text,
              }}
              placeholder="Search "
            />
          </div>
        </div>
      
      </div>
      <Menu sort={sort} />

      <div
        className="scroll"
        style={{ overflow: "scroll", width: "100%", height: "100%" }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={35} width={35} />
          </div>
        ) : summary.length > 0 ? (
          summary.map((i, t) => {
            return <SummaryCard item={i} reload={LoadSummary} />;
          })
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textTransform: "uppercase",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Client Summary Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}
