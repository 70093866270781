import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Redux from "./Component/Redux/Redux";
import App from "./App";
import { Provider } from "react-redux";
import { createStore } from "redux";

const store = createStore(Redux);

function Apps() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

ReactDOM.render(<Apps />, document.getElementById("root"));
