import { View, Text } from "@react-pdf/renderer";
import React from "react";
import { Dark } from "../../../../../../Theme/Theme";
import moment from "moment";

export default function PdfCard(props) {
  const i = props.item;
  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.text,
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}> {i.symbol}</Text>
      </View>
  
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {" "}
          {parseFloat(i.qty_per_order).toFixed(2)}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {" "}
          {parseFloat(i.total_quantity).toFixed(2)}
        </Text>
      </View>
    </View>
  );
}
