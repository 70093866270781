import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { Calendar } from "primereact/calendar";
import "./Filter.css";
import moment from "moment";
export default function Filter(props) {
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="scroll" style={{ width: "100%", overflowY: "scroll" }}>
        <div
          onClick={() => {
            props.filter(
              moment().format(),
              moment().format(),
              "TODAY"
            );
            props.updatefilter("TODAY");
          }}
          style={{
            height: 50,
            backgroundColor: Dark.background,
            width: "100%",
            color: Dark.text,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 10,
            borderRadius: 10,
            paddingLeft: 20,
          }}
        >
          <div style={{ paddingRight: 20, textTransform: "uppercase" }}>
            TODAY
          </div>
        </div>
        <div
          onClick={() => {
            props.filter(
              moment()
                .subtract(0, "weeks")
                .startOf("week")
                .format(),
              moment().subtract(0, "weeks").endOf("week").format(),
              "THISWEEK"
            );
            props.updatefilter("THISWEEK");
          }}
          style={{
            height: 50,
            backgroundColor: Dark.background,
            width: "100%",
            color: Dark.text,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 10,
            borderRadius: 10,
            paddingLeft: 20,
          }}
        >
          <div style={{ paddingRight: 20, textTransform: "uppercase" }}>
            THIS WEEK
          </div>
        </div>
        <div
          onClick={() => {
            props.filter(
              moment()
                .subtract(1, "weeks")
                .startOf("week")
                .format(),
              moment().subtract(1, "weeks").endOf("week").format(),
              "LASTWEEK"
            );
            props.updatefilter("LASTWEEK");
          }}
          style={{
            height: 50,
            backgroundColor: Dark.background,
            width: "100%",
            color: Dark.text,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 10,
            borderRadius: 10,
            paddingLeft: 20,
          }}
        >
          <div style={{ paddingRight: 20, textTransform: "uppercase" }}>
            LAST WEEK
          </div>
        </div>

        <div
          style={{
            height: 80,
            backgroundColor: Dark.background,
            width: "100%",
            color: Dark.text,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 10,
            borderRadius: 10,
            paddingLeft: 20,
          }}
        >
          <div
            style={{
              paddingRight: 20,
              textTransform: "uppercase",
              width: 200,
              fontSize: 12,
            }}
          >
            CUSTOM DATE
          </div>
          <div
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              borderRadius: 10,
              marginRight: 10,
            }}
          >
            START DATE
            <Calendar
              dateFormat="dd-mm-yy"
              style={{ width: 140 }}
              value={start}
              onChange={(e) => {
                setStart(e.value);
              }}
            />
          </div>
          <div
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              borderRadius: 10,
            }}
          >
            END DATE
            <Calendar
              dateFormat="dd-mm-yy"
              style={{ width: 140 }}
              value={end}
              onChange={(e) => setEnd(e.value)}
            />
          </div>

          <div
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              borderRadius: 10,
            }}
          >
            <div
              onClick={() => {
                props.filter(start, end);
                props.updatefilter("CUSTOM");
              }}
              style={{
                padding: 10,
                backgroundColor: Dark.primary,
                margin: 10,
                borderRadius: 10,
              }}
            >
              SUBMIT
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
