import React, { useContext, useEffect, useState } from "react";
import RightCard from "./RightCard/RightCard";
import ExtraCard from "./ExtraCard/ExtraCard";
import { Dark } from "../../../Theme/Theme";
import Marquee from "./Marquee";
import hotkeys from "hotkeys-js";

import useSize from "../../../Utils/useSize";

export default function Home() {
  const windowSize = useSize();

  const [show, setShow] = useState(true);

  hotkeys("esc", function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault();
    setShow(!show);
  });

  return (
    <div
      style={{
        backgroundColor: Dark.secondary,
        height: windowSize[1] - 130,
        width: windowSize[0],
      }}
    >
      <div
        style={{
          backgroundColor: Dark.background,
        }}
      >
        <Marquee />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 4,
            backgroundColor: Dark.background,
            marginBottom: 0,
          }}
        >
          <RightCard
            status={show}
            show={() => setShow(true)}
            hide={() => setShow(false)}
          />
        </div>
        {show ? (
          <div
            style={{
              flex: 1.5,
              backgroundColor: Dark.background,
              display: "flex",
              height: windowSize[1] - 180,
            }}
          >
            <ExtraCard />
          </div>
        ) : null}
      </div>
    </div>
  );
}
