// components/PdfViewer.js
// eslint-disable-next-line no-unused-vars
import React from "react";
import PdfDocument from "./PDFGenerator";

const PdfViewer = (props) => {
  return (
    <div className="flex items-center justify-center h-screen">
      <PdfDocument
        brokerage={props.brokerage}
        pl={props.pl}
        futurepl={props.futurepl}
        futurebrok={props.futurebrok}
        futurevol={props.futurevol}
        mcxpl={props.mcxpl}
        mcxbrok={props.mcxbrok}
        mcxvol={props.mcxvol}
        optionspl={props.optionspl}
        optionsbrok={props.optionsbrok}
        optionsvol={props.optionsvol}
        trade={props.trade}
        username={props.username}
        name={props.name}
        start={props.start}
        end={props.end}
        deposit_total={props.deposit}
      />
    </div>
  );
};

export default PdfViewer;
