import React from "react";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { Dark, Light } from "../../../../Theme/Theme";

export default function RightPl(props) {
  const btotal = useSelector((state) => state.btotal);
  const total = useSelector((state) => state.total);
  const theme = useSelector((state) => state.theme);
  let m2m = parseFloat(parseFloat(btotal) + parseFloat(total)).toFixed(0);
  return (
    <div key={props.standing.length} style={{display:"flex"}} >
      <div
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          color: Dark.text,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          backgroundColor: theme == 0 ? Dark.background : Light.background,
          width: 120,
          height: 50,
          marginLeft: 10,
        }}
      >
        <div style={{ fontSize: 12 }}> M2M </div>
        <div style={{ color: m2m > 0 ? Dark.buy : Dark.sell, fontSize: 14 }}>
          <NumericFormat
            value={m2m}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>

      <div
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          color: Dark.text,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          backgroundColor: theme == 0 ? Dark.background : Light.background,
          width: 120,
          height: 50,
          marginLeft: 10,
        }}
      >
        <div style={{ fontSize: 12 }}> BROKERAGE </div>
        <div style={{ color: Dark.sell, fontSize: 14 }}>
          <NumericFormat
            value={btotal.toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>

      <div
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          color: Dark.text,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          backgroundColor: theme == 0 ? Dark.background : Light.background,
          width: 120,
          height: 50,
          marginLeft: 10,
        }}
      >
        <div style={{ fontSize: 12 }}> LIVE P&L </div>
        <div style={{ color: total > 0 ? Dark.buy : Dark.sell, fontSize: 14 }}>
          <NumericFormat
            value={total.toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>
    </div>
  );
}
