import { View, Text } from "@react-pdf/renderer";
import React from "react";
import { Dark } from "../../../../../../Theme/Theme";
import moment from "moment";

export default function PdfCard(props) {
  const i = props.item;
  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.text,
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {" "}
          {i.symbol}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: i.buy_sell == 0 ? Dark.buy : Dark.sell }}>
          {" "}
          {i.buy_sell == 0 ? "BUY" : "SELL"}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {" "}
          {i.qty}
        </Text>
      </View>
      <View
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.sell,textAlign:"center" }}>
          {" "}
          {i.msg}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {" "}
          {moment(i.date_created).format("DD-MM-YYYY hh:mm:ss")}
        </Text>
      </View>
    </View>
  );
}
