import React from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
import { IoMdCloseCircle } from "react-icons/io";
export default function FsqCard(props) {
  const item = props.item;
  const watch = props.watch;
  const limit = props.limit;
  const fsq = props.fsq;
  const fatak = props.fatak;
  const wallet = props.wallet;
  const intraday = props.intraday;

  return (
    <>
      {watch == 1 && item.type == "WATCH" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
            padding: 10,
            backgroundColor: Dark.secondary,
            borderRadius: 10,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                color: Dark.text,
                fontSize: 14,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              WATCH
            </div>
            <div style={{ color: Dark.text, fontSize: 14 }}>
              Client : {item.client_username}
            </div>
            <div
              style={{
                color:
                  item.buy_sell == 0
                    ? Dark.buy
                    : item.buy_sell == 1
                    ? Dark.sell
                    : Dark.text,
                paddingTop: 7,
                fontSize: 14,
              }}
            >
              {item.title}
            </div>
            <div
              style={{
                color:
                  item.buy_sell == 0
                    ? Dark.buy
                    : item.buy_sell == 1
                    ? Dark.sell
                    : Dark.text,
                paddingTop: 5,
                fontSize: 14,
              }}
            >
              {item.body}
            </div>
            <div
              style={{
                color: Dark.text,
                paddingTop: 5,
                fontSize: 14,
              }}
            >
              {moment(item.date_created).format("DD-MM-YYYY HH:mm:ss")}
            </div>
          </div>
          {/* <div>
            <IoMdCloseCircle size={35} color={Dark.primary} />
          </div> */}
        </div>
      ) : null}
      {limit == 1 && item.type == "LIMIT" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
            padding: 10,
            backgroundColor: Dark.secondary,
            borderRadius: 10,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                color: Dark.text,
                fontSize: 14,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              LIMIT
            </div>
            <div style={{ color: Dark.text, fontSize: 14 }}>
              Client : {item.client_username}
            </div>
            <div
              style={{
                color:
                  item.buy_sell == 0
                    ? Dark.buy
                    : item.buy_sell == 1
                    ? Dark.sell
                    : Dark.text,
                paddingTop: 7,
                fontSize: 14,
              }}
            >
              {item.title}
            </div>
            <div
              style={{
                color:
                  item.buy_sell == 0
                    ? Dark.buy
                    : item.buy_sell == 1
                    ? Dark.sell
                    : Dark.text,
                fontSize: 14,
                paddingTop: 5,
              }}
            >
              {item.body}
            </div>
          </div>
          {/* <div>
            <IoMdCloseCircle size={35} color={Dark.primary} />
          </div> */}
        </div>
      ) : null}
      {fsq == 1 && item.type == "FSQ" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
            padding: 10,
            backgroundColor: Dark.secondary,
            borderRadius: 10,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                color: Dark.text,
                fontSize: 14,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              FORCE SQ LOG
            </div>
            <div style={{ color: Dark.text, fontSize: 14 }}>
              Client : {item.client_username}
            </div>
            <div style={{ color: Dark.sell, paddingTop: 7, fontSize: 14 }}>
              P&L : {item.pl}
            </div>
            <div style={{ color: Dark.sell, paddingTop: 5, fontSize: 14 }}>
              {item.msg}
            </div>
          </div>
          {/* <div>
            <IoMdCloseCircle size={35} color={Dark.primary} />
          </div> */}
        </div>
      ) : null}
      {fatak == 1 && item.type == "FATAK" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
            padding: 10,
            backgroundColor: Dark.secondary,
            borderRadius: 10,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                color: Dark.text,
                fontSize: 14,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              LIMIT REACHED (FATAK)
            </div>
            <div style={{ color: Dark.text, fontSize: 14 }}>
              Client : {item.client_username}
            </div>
            <div style={{ color: Dark.sell, paddingTop: 7, fontSize: 14 }}>
              LIMIT : {item.pl}
            </div>
            <div style={{ color: Dark.sell, paddingTop: 5, fontSize: 14 }}>
              {item.msg}
            </div>
          </div>
          {/* <div>
            <IoMdCloseCircle size={35} color={Dark.primary} />
          </div> */}
        </div>
      ) : null}
      {intraday == 1 && wallet == 1 && item.type == "WALLET" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
            padding: 10,
            backgroundColor: Dark.secondary,
            borderRadius: 10,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                color: Dark.text,
                fontSize: 14,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              WALLET
            </div>
            <div style={{ color: Dark.text, fontSize: 14 }}>
              Client : {item.client_username}
            </div>
            <div style={{ color: Dark.text, paddingTop: 7, fontSize: 14 }}>
              AMOUNT : {item.pl}
            </div>
            <div style={{ color: item.buy_sell == 0 ? Dark.buy : Dark.sell, paddingTop: 5, fontSize: 14 }}>
              {item.title}
            </div>
          </div>
          {/* <div>
            <IoMdCloseCircle size={35} color={Dark.primary} />
          </div> */}
        </div>
      ) : null}
    </>
  );
}
