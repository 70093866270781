import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import { BsFilePdf } from "react-icons/bs";
import Client from "./Client/Client";
import Symbol from "./Symbol/Symbol";
import Filter from "./Filter/Filter";
import TradePdf from "./TradePdf/TradePdf";
import moment from "moment";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

export default function TopCard(props) {
  const [client, setClient] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [filter, setFilter] = useState(false);

  const [filtertype, setFilterType] = useState("");

  const [client_data, setClientData] = useState("");
  const [symbol_data, setSymbolData] = useState("");

  const Clear = () => {
    setClientData("");
    setSymbolData("");
    setFilterType("");
    props.client("");
    props.symbol("");
    props.filter("");
    props.reload();
  };

  var buy_volume = props.buy_volume == null ? 0 : props.buy_volume;
  var sell_volume = props.sell_volume == null ? 0 : props.sell_volume;
  var profit_loss = props.profit_loss;
  var brokerage = props.brokerage;
  let m2m = 0;

  m2m = profit_loss + brokerage;

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: Dark.background,
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>Profit/Loss</div>
          <div
            style={{
              color: profit_loss > 0 ? Dark.buy : Dark.sell,
              marginTop: 10,
              fontSize: 18,
            }}
          >
            {profit_loss == null ? 0 : parseFloat(profit_loss).toFixed(2)}
          </div>
        </div>
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>M2M</div>
          <div
            style={{
              color: m2m > 0 ? Dark.buy : Dark.sell,
              marginTop: 10,
              fontSize: 18,
            }}
          >
            {m2m == null ? 0 : parseFloat(m2m).toFixed(2)}
          </div>
        </div>
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>BROKERAGE</div>
          <div
            style={{
              color: Dark.sell,
              marginTop: 10,
              fontSize: 18,
            }}
          >
            {brokerage == null ? 0 : parseFloat(brokerage).toFixed(2)}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>BUY VOLUME</div>
          <div
            style={{
              color: Dark.buy,
              marginTop: 10,
              fontSize: 18,
            }}
          >
            {parseFloat(buy_volume).toFixed(2)}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>SELL VOLUME</div>
          <div
            style={{
              color: Dark.sell,
              marginTop: 10,
              fontSize: 18,
            }}
          >
            {parseFloat(sell_volume).toFixed(2)}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => setClient(true)}
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>Client</div>
          <div
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 18,
            }}
          >
            {client_data == "" ? "No Select" : client_data.username}
          </div>
        </div>

        <div
          onClick={() => setSymbol(true)}
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>Symbol</div>
          <div
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 18,
            }}
          >
            {symbol_data == "" ? "No Select" : symbol_data}
          </div>
        </div>

        <div
          onClick={() => setFilter(true)}
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
            textAlign: "center",
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>Filter</div>
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              fontSize: 18,
            }}
          >
            {filtertype == "" || filtertype == "CUSTOM" ? (
              <>
                <div style={{ marginBottom: 3, fontSize: 12 }}>
                  START : {moment(props.start).format("DD-MM-YYYY")}
                </div>
                <div style={{ fontSize: 12 }}>
                  END :{moment(props.end).format("DD-MM-YYYY")}
                </div>
              </>
            ) : (
              filtertype
            )}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "50px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              color: Dark.text,
            }}
          >
            <PDFDownloadLink
              style={{
                textDecoration: "none",
              }}
              document={
                <TradePdf
                  buy_volume={props.buy_volume}
                  sell_volume={props.sell_volume}
                  trade={props.trade}
                  profit_loss={profit_loss}
                  brokerage={brokerage}
                  m2m={m2m}
                />
              }
              fileName={"Trade"}
            >
              {({ blob, url, loading, error }) => (
                <div style={{ color: Dark.text }}>
                  <BsFilePdf size={30} color={Dark.text} />
                </div>
              )}
            </PDFDownloadLink>
          </div>
        </div>
      </div>

      <Dialog
        header={"Client List"}
        visible={client}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setClient(false)}
      >
        <Client
          client={props.client}
          start={props.start}
          end={props.end}
          updateclient={(a) => setClientData(a)}
          close={() => setClient(false)}
        />
      </Dialog>

      <Dialog
        header={"Symbol List"}
        visible={symbol}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setSymbol(false)}
      >
        <Symbol
          start={props.start}
          end={props.end}
          symbol={props.symbol}
          updatesymbol={(a) => setSymbolData(a)}
          close={() => setSymbol(false)}
        />
      </Dialog>

      <Dialog
        header={"Select Filter"}
        visible={filter}
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setFilter(false)}
      >
        <Filter
          start={props.start}
          end={props.end}
          filter={props.filter}
          updatefilter={(a) => {
            setFilterType(a);
            setFilter(false);
          }}
        />
      </Dialog>
    </div>
  );
}
