import React from "react";
import {
  Page,
  Document,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import Header from "./Header";
import TestingPage from "./ContentSection";
import moment from "moment";
import LastFooterSection from "./LastFooterSection";
import { Dark } from "../../../../../Theme/Theme";
import { BsFilePdf } from "react-icons/bs";

Font.register({
  family: "Times-Bold",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  Family: {
    fontFamily: "Times-Bold",
  },
  headingText1: {
    borderBottom: "2px",
    borderBottomColor: "black",
    textAlign: "center",
  },
  subHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
    marginBottom: "3px",
  },
  boldText: {
    fontWeight: "bold",
  },
  page: {
    fontFamily: "Helvetica",
    paddingTop: 20,
    paddingBottom: 50,
    paddingHorizontal: 30,
  },

  footer: {
    position: "absolute",
    bottom: 0,
    right: 10,
    textAlign: "center",
    marginTop: 10,
    fontSize: 10,
    color: "blue",
  },
  content: {
    marginBottom: 8,
  },
  headingText2: {
    borderBottom: "2px",
    borderBottomColor: "black",
  },
});

// JS + react-pdf/render section
const PdfDocument = (props) => {
  let currentPageContent = []; // Track the content of the current page
  const pages = []; // Store all pages
  let firstPage = 1; // Flag to track the first page
  let count = 0;

  for (let i = 0; i < props.client.length; i++) {
    const data = props.client[i];
    if (i == count) {
      count = parseFloat(count) + 20;

      // Create a new page with the accumulated content
      if (currentPageContent.length > 0) {
        pages.push(
          <Page key={i} size="A4" style={styles.page}>
            <View style={styles.headingText2}>
              <Text
                style={[
                  styles.Family,
                  {
                    fontSize: 12,
                    marginBottom: "8px",
                    textAlign: "center",
                  },
                ]}
              >
                SOFT
              </Text>
            </View>
            {/* second sub-heading */}
            <View style={styles.subHeading}>
              <Text
                style={[
                  styles.Family,
                  {
                    width: "42%",
                    backgroundColor: "#C6C6C5",
                    padding: "4px",
                    textAlign: "left",
                    fontSize: "11px",
                  },
                ]}
              >
                Broker Report
              </Text>

              <Text
                style={[
                  styles.Family,
                  {
                    width: "46%",
                    backgroundColor: "#C6C6C5",
                    padding: "4px",
                    textAlign: "right",
                    fontSize: "9px",
                  },
                ]}
              >
                Account Statement From{" "}
                {moment(props.start).format("DD-MM-YYYY")} To{" "}
                {moment(props.end).format("DD-MM-YYYY")}
              </Text>
            </View>

            <Header />

            {currentPageContent}
            <View
              style={styles.footer}
              render={({ pageNumber, totalPages }) => (
                <Text style={{ fontSize: 8, marginBottom: "10px" }}>
                  {" "}
                  Page {pageNumber} of {totalPages}
                </Text>
              )}
              fixed
            />
          </Page>
        );
      }
      currentPageContent = []; // Clear the current content
    }

    currentPageContent.push(
      <React.Fragment key={i}>
        {i != count && <React.Fragment>{(firstPage += 1)}</React.Fragment>}
        <View>
          {" "}
          <TestingPage data={data} />
        </View>
      </React.Fragment>
    );

    // If it's the last iteration, add the last accumulated content as a page
    if (i === props.client.length - 1) {
      pages.push(
        <Page key={i} size="A4" style={styles.page}>
          <View style={styles.headingText2}>
            <Text
              style={[
                styles.Family,
                {
                  fontSize: 12,
                  marginBottom: "8px",
                  textAlign: "center",
                },
              ]}
            >
              SOFT
            </Text>
          </View>
          {/* second sub-heading */}
          <View style={styles.subHeading}>
            <Text
              style={[
                styles.Family,
                {
                  width: "42%",
                  backgroundColor: "#C6C6C5",
                  padding: "4px",
                  textAlign: "left",
                  fontSize: "11px",
                },
              ]}
            >
              Broker Report
            </Text>

            <Text
              style={[
                styles.Family,
                {
                  width: "46%",
                  backgroundColor: "#C6C6C5",
                  padding: "4px",
                  textAlign: "right",
                  fontSize: "9px",
                },
              ]}
            >
              Account Statement From {moment(props.start).format("DD-MM-YYYY")}{" "}
              To {moment(props.end).format("DD-MM-YYYY")}
            </Text>
          </View>
          <Header />
          {currentPageContent}
          <View
            style={styles.footer}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 8, marginBottom: "10px" }}>
                {" "}
                Page {pageNumber} of {totalPages}
              </Text>
            )}
            fixed
          />
          {/* Add the LastFooterSection component after the last data */}

          <LastFooterSection
            pl={props.pl}
            brokerage={props.brokerage}
            brokerage_broker={props.brokerage_broker}
            future={props.future}
            mcx={props.mcx}
            options={props.options}
            futurebroker={props.futurebroker}
            mcxbroker={props.mcxbroker}
            optionebroker={props.optionebroker}
            gross={props.gross}
            sharing={props.sharing}
            netamount={props.netamount}
          />
        </Page>
      );
    }
  }

  return (
    <PDFDownloadLink
      style={{
        textDecoration: "none",
      }}
      document={<Document>{pages}</Document>}
      fileName={
        props.selectbroker == "" ||
        props.selectbroker == null ||
        props.selectbroker == undefined
          ? `Broker Report`
          : `${props.selectbroker.broker_name}`
      }
    >
      {({ blob, url, loading, error }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ color: Dark.text }}>
            <BsFilePdf fontSize={30} />
          </div>
        </div>
      )}
    </PDFDownloadLink>
  );
};

export default PdfDocument;
