import React from "react";
import { Dark } from "../../../Theme/Theme";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
export default function Menu(props) {
  return (
    <div
      style={{
        height: 30,
        display: "flex",
        backgroundColor: Dark.primary,
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
        fontSize:14
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Client</div>
        <div onClick={() => props.sort("ClientUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}}  />
        </div>
        <div
          onClick={() => props.sort("ClientDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown  style={{fontSize:10}}/>
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Symbol</div>
        <div onClick={() => props.sort("SymbolUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div
          onClick={() => props.sort("SymbolDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown  style={{fontSize:10}}/>
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Buy-Sell</div>
        <div onClick={() => props.sort("BuySellUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div
          onClick={() => props.sort("BuySellDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{fontSize:10}} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Net-Qty</div>
        <div onClick={() => props.sort("QtyUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div onClick={() => props.sort("QtyDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{fontSize:10}} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Rate</div>
        <div onClick={() => props.sort("RateUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div onClick={() => props.sort("RateDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{fontSize:10}} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Type</div>
        <div onClick={() => props.sort("TypeUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div onClick={() => props.sort("TypeDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{fontSize:10}} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Balance</div>
        <div onClick={() => props.sort("BalanceUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div
          onClick={() => props.sort("BalanceDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{fontSize:10}} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>M2M</div>
        <div onClick={() => props.sort("M2MUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div onClick={() => props.sort("M2MDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{fontSize:10}} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Brokerage</div>
        <div onClick={() => props.sort("BrokUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div onClick={() => props.sort("BrokDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{fontSize:10}} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>P/L</div>
        <div onClick={() => props.sort("PlUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div onClick={() => props.sort("PlDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{fontSize:10}} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Other</div>
        <div onClick={() => props.sort("OtherUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div onClick={() => props.sort("OtherDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{fontSize:10}}/>
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>DATE</div>
        <div onClick={() => props.sort("DateUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{fontSize:10}} />
        </div>
        <div onClick={() => props.sort("DateDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{fontSize:10}} />
        </div>
      </div>
    </div>
  );
}
