import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import Client from "./Client/Client";
import Symbol from "./Symbol/Symbol";
import Right from "./Right/Right";

export default function TopCard(props) {
  const [client, setClient] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [client_data, setClientData] = useState("");
  const [symbol_data, setSymbolData] = useState("");

  const clearclient = () => {
    setClientData("");
    props.reload();
  };
  const clearsymbol = () => {
    setSymbolData("");
    props.reload();
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: Dark.background,
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Right booked={props.booked} />

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          alignItems: "center",
          textTransform: "uppercase",
        }}
      >
        <div
          style={{
            height: "60px",
            width: "140px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            onClick={() => clearclient()}
            style={{
              color: client_data == "" ? Dark.text : Dark.sell,
              fontSize: 14,
            }}
          >
            {client_data == "" ? "CLIENT" : "CLEAR"}
          </div>
          <div
            onClick={() => setClient(true)}
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {client_data == "" ? "SEARCH" : client_data.username}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "140px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            onClick={() => clearsymbol()}
            style={{
              color: symbol_data == "" ? Dark.text : Dark.sell,
              fontSize: 14,
            }}
          >
            {symbol_data == "" ? "SYMBOL" : "CLEAR"}
          </div>
          <div
            onClick={() => setSymbol(true)}
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {symbol_data == "" ? "SEARCH" : symbol_data}
          </div>
        </div>
      </div>

      <Dialog
        header={"Client List"}
        visible={client}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setClient(false)}
      >
        <Client
          symbol_id={props.symbol_id}
          client={props.client}
          updateclient={(a) => {
            setClientData(a);
          }}
          close={() => setClient(false)}
        />
      </Dialog>

      <Dialog
        header={"Symbol List"}
        visible={symbol}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setSymbol(false)}
      >
        <Symbol
          client_id={props.client_id}
          symbol={props.symbol}
          updatesymbol={(a) => setSymbolData(a)}
          close={() => setSymbol(false)}
        />
      </Dialog>
    </div>
  );
}
