// components/PdfViewer.js
// eslint-disable-next-line no-unused-vars
import React from "react";
import PdfDocument from "./PDFGenerator";

const PdfViewer = (props) => {
  return (
    <div className="flex items-center justify-center h-screen">
      <PdfDocument
        selectbroker={props.selectbroker}
        client={props.client}
        pl={props.pl}
        brokerage={props.brokerage}
        brokerage_broker={props.brokerage_broker}
        future={props.future}
        mcx={props.mcx}
        options={props.options}
        futurebroker={props.futurebroker}
        mcxbroker={props.mcxbroker}
        optionebroker={props.optionebroker}
        start={props.start}
        end={props.end}
        gross={props.gross}
        sharing={props.sharing}
        netamount={props.netamount}
      />
    </div>
  );
};

export default PdfViewer;
