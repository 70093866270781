import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Card from "./Card";
import "./NiftyBankNifty.css";
const backend = new Backend();

export default function NiftyBankNifty() {
  const [index, setIndex] = useState([]);

  useEffect(() => {
    LoadIndex();
  }, []);

  const LoadIndex = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_index(data).then((r) => {
      if (r.error == "False") {
        setIndex(r.index);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div className="mainbanknifty">
      <div className="bankniftycontainer">
        {index.length > 0
          ? index.map((i) => {
              return <Card key={i.id} item={i} />;
            })
          : null}
      </div>
    </div>
  );
}
