import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { InputText } from "primereact/inputtext";
import "./Edit.css";
import Active from "../Active/Active";

import SymbolBrokerage from "../SymbolBrokerage/SymbolBrokerage";

import AutoSq from "../Active/AutoSq";

import OnlySq from "../Active/OnlySq";

import Intraday from "../Active/Intraday";

import Notification from "../Active/Notification";
import Watch from "../Active/Watch";
import { Dialog } from "primereact/dialog";
import { ToWords } from "to-words";
import { NumericFormat } from "react-number-format";

const toWords = new ToWords({
  localeCode: "hi-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "₹",
      plural: "₹",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

const backend = new Backend();

export default function Edit(props) {
  const client = props.client;

  const [loading, setLoading] = useState(false);

  const [brokerage, setbrokerage] = useState(false);

  const [clientS, setClientS] = useState("");

  const [name, setName] = useState("");

  const [fund, setfund] = useState("");
  const [password, setPassword] = useState("");

  const [future_brokerage, setfuture_brokerage] = useState("");
  const [mcx_brokerage, setmcx_brokerage] = useState("");
  const [options_brokerage, setoptions_brokerage] = useState("");

  useEffect(() => {
    loadClientSingle();
  }, []);

  const loadClientSingle = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client.id,
    };

    backend.single_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClientS(r.client);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "90%",
      }}
    >
      <div
        key={clientS}
        style={{
          flex: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: Dark.text,
            padding: 10,
            textTransform: "uppercase",
            backgroundColor: Dark.primary,
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>Client details</div>
          <div style={{ textTransform: "uppercase" }}>
            {" "}
            opening balance :{" "}
            <NumericFormat
              value={clientS.deposit_total}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>{clientS.name}</div>
            <div className="loginInputEditAdmin">
              <InputText
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Name"
              />
            </div>
          </div>

          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>
              <NumericFormat
                value={clientS.deposit_current}
                displayType={"text"}
                thousandSeparator={true}
              />{" "}
              ₹
            </div>
            <div className="loginInputEditAdmin">
              <InputText
                value={fund}
                onChange={(e) => {
                  setfund(e.target.value);
                }}
                placeholder="Fund - & +"
              />
            </div>
            <div style={{ color: Dark.text, marginTop: 5 }}>
              {fund == "" ? null : fund > 0 ? toWords.convert(fund) : null }
            </div>
          </div>

          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>PASSWORD</div>
            <div className="loginInputEditAdmin">
              <InputText
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            color: Dark.text,
            padding: 10,
            textTransform: "uppercase",
            backgroundColor: Dark.primary,
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>client brokerage</div>

        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text }}>FUTURE</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                {clientS.future_brokerage}
              </div>
              <InputText
                value={future_brokerage}
                onChange={(e) => {
                  setfuture_brokerage(e.target.value);
                }}
                type="number"
                placeholder="Enter Brokerage"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>MCX</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                {clientS.lot_volume == 0 ? "L" : "V"} {clientS.mcx_brokerage}
              </div>
              <InputText
                value={mcx_brokerage}
                onChange={(e) => {
                  setmcx_brokerage(e.target.value);
                }}
                placeholder="Enter Brokerage"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>OPTIONS</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                {clientS.options_brokerage}
              </div>
              <InputText
                value={options_brokerage}
                onChange={(e) => {
                  setoptions_brokerage(e.target.value);
                }}
                placeholder="Enter Brokerage"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 0.5,
          height: "55px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Active
          status={clientS.status}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
        <Notification
          status={clientS.broker_notification}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
        <Watch
          status={clientS.watch}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
      </div>

      <div
        style={{
          flex: 0.5,
          height: "55px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => setbrokerage(true)}
          style={{
            padding: 10,
            backgroundColor: Dark.primary,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 7,
            color: Dark.text,
          }}
        >
          Symbol Brokerage
        </div>


        <AutoSq
          status={clientS.auto_sq}
          client_id={clientS.id}
          reload={loadClientSingle}
        />

        <OnlySq
          status={clientS.only_sq}
          client_id={clientS.id}
          reload={loadClientSingle}
        />

        <Intraday
          status={clientS.intraday}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
      </div>

      <Dialog
        header="SYMBOL WISE BROKERAGE"
        visible={brokerage}
        className="modal"
        style={{ width: "30vw", height: "60vh" }}
        onHide={() => setbrokerage(false)}
      >
        <SymbolBrokerage client_id={clientS.id} reload={loadClientSingle} />
      </Dialog>
    </div>
  );
}
