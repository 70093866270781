import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { AiFillCheckCircle } from "react-icons/ai";
export default function ClientCard(props) {
  const item = props.item;
  const [show, setShow] = useState(false);
  return (
    <div
      onClick={() => {
        setShow(!show);
        if (show) {
          props.RemoveClient(item.id);
        } else {
          props.AddClient(item.id);
        }
      }}
      style={{
        height: 55,
        display: "flex",
        backgroundColor: Dark.background,
        marginBottom: 10,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
        borderStyle: "solid",
        borderColor: show ? Dark.primary : Dark.background,
        borderWidth: 2,
      }}
    >
      {show ? (
        <div style={{ paddingRight: 10 }}>
          <AiFillCheckCircle size={30} color={Dark.primary} />
        </div>
      ) : null}

      <div style={{ flex: 1, color: Dark.text }}>
        {item.username} ({item.name})
      </div>
      <div
        style={{
          color: item.deposit_current > 0 ? Dark.buy : Dark.sell,
          fontSize: 10,
        }}
      >
        BALANCE : {item.deposit_current}
      </div>
    </div>
  );
}
