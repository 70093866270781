import React from "react";
import {
  Page,
  Document,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import Header from "./Header";
import TestingPage from "./ContentSection";
import moment from "moment";
import LastFooterSection from "./LastFooterSection";
import { Dark } from "../Theme/Theme";

Font.register({
  family: "Times-Bold",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  Family: {
    fontFamily: "Times-Bold",
  },
  headingText1: {
    borderBottom: "2px",
    borderBottomColor: "black",
    textAlign: "center",
  },
  subHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
    marginBottom: "3px",
  },
  boldText: {
    fontWeight: "bold",
  },
  page: {
    fontFamily: "Helvetica",
    paddingTop: 20,
    paddingBottom: 50,
    paddingHorizontal: 30,
  },

  footer: {
    position: "absolute",
    bottom: 0,
    right: 10,
    textAlign: "center",
    marginTop: 10,
    fontSize: 10,
    color: "blue",
  },
  content: {
    marginBottom: 8,
  },
  headingText2: {
    borderBottom: "2px",
    borderBottomColor: "black",
  },
});

// JS + react-pdf/render section
const PdfDocument = (props) => {
  let currentPageContent = []; // Track the content of the current page
  const pages = []; // Store all pages
  let firstPage = 1; // Flag to track the first page
  let count = 0;

  for (let i = 0; i < props.trade.length; i++) {
    const data = props.trade[i];
    if (i == count) {
      count = parseFloat(count) + 2;

      // Create a new page with the accumulated content
      if (currentPageContent.length > 0) {
        pages.push(
          <Page key={i} size="A4" style={styles.page}>
            <View style={styles.headingText2}>
              <Text
                style={[
                  styles.Family,
                  {
                    fontSize: 12,
                    marginBottom: "8px",
                    textAlign: "center",
                  },
                ]}
              >
                SOFT
              </Text>
            </View>
            {/* second sub-heading */}
            <View style={styles.subHeading}>
              <Text
                style={[
                  styles.Family,
                  {
                    width: "42%",
                    backgroundColor: "#C6C6C5",
                    padding: "4px",
                    textAlign: "left",
                    fontSize: "11px",
                  },
                ]}
              >
                {props.username} ({props.name})
              </Text>

              <Text
                style={[
                  styles.Family,
                  {
                    width: "46%",
                    backgroundColor: "#C6C6C5",
                    padding: "4px",
                    textAlign: "right",
                    fontSize: "9px",
                  },
                ]}
              >
                Account Statement From{" "}
                {moment(props.start).format("DD-MM-YYYY")} To{" "}
                {moment(props.end).format("DD-MM-YYYY")}
              </Text>
            </View>

            {currentPageContent}
            <View
              style={styles.footer}
              render={({ pageNumber, totalPages }) => (
                <Text style={{ fontSize: 8, marginBottom: "10px" }}>
                  {" "}
                  Page {pageNumber} of {totalPages}
                </Text>
              )}
              fixed
            />
          </Page>
        );
      }
      currentPageContent = []; // Clear the current content
    }

    currentPageContent.push(
      <React.Fragment key={i}>
        {i != count && (
          <React.Fragment>
            <Header />
            {/* NSE View */}
            {/* {firstPage == 1 && ( // Only display the content below Header on the first page
              <View
                style={{
                  backgroundColor: "#C6C6C5",
                  width: "100%",
                  padding: "4px",
                  textAlign: "center",
                  marginTop: "-15px",
                }}
              >
                <Text style={[styles.Family, { fontSize: "8px" }]}>
                  {data[i].script_type == "fut"
                    ? "NSE"
                    : data[i].script_type == "mcx"
                    ? "MCX"
                    : "FO"}
                  NSE
                </Text>
              </View>
            )} */}
            {(firstPage += 1)}
          </React.Fragment>
        )}
        <View>
          <TestingPage data={data} />
        </View>
      </React.Fragment>
    );

    // If it's the last iteration, add the last accumulated content as a page
    if (i === props.trade.length - 1) {
      pages.push(
        <Page key={i} size="A4" style={styles.page}>
          <View style={styles.headingText2}>
            <Text
              style={[
                styles.Family,
                {
                  fontSize: 12,
                  marginBottom: "8px",
                  textAlign: "center",
                },
              ]}
            >
              SOFT
            </Text>
          </View>
          {/* second sub-heading */}
          <View style={styles.subHeading}>
            <Text
              style={[
                styles.Family,
                {
                  width: "42%",
                  backgroundColor: "#C6C6C5",
                  padding: "4px",
                  textAlign: "left",
                  fontSize: "11px",
                },
              ]}
            >
              {props.username} ({props.name})
            </Text>

            <Text
              style={[
                styles.Family,
                {
                  width: "46%",
                  backgroundColor: "#C6C6C5",
                  padding: "4px",
                  textAlign: "right",
                  fontSize: "9px",
                },
              ]}
            >
              Account Statement From {moment(props.start).format("DD-MM-YYYY")}{" "}
              To {moment(props.end).format("DD-MM-YYYY")}
            </Text>
          </View>

          {currentPageContent}
          <View
            style={styles.footer}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 8, marginBottom: "10px" }}>
                {" "}
                Page {pageNumber} of {totalPages}
              </Text>
            )}
            fixed
          />
          {/* Add the LastFooterSection component after the last data */}
          <LastFooterSection
            brokerage={props.brokerage}
            pl={props.pl}
            futurepl={props.futurepl}
            futurebrok={props.futurebrok}
            futurevol={props.futurevol}
            mcxpl={props.mcxpl}
            mcxbrok={props.mcxbrok}
            mcxvol={props.mcxvol}
            optionspl={props.optionspl}
            optionsbrok={props.optionsbrok}
            optionsvol={props.optionsvol}
          />
        </Page>
      );
    }
  }

  return (
    // <PDFViewer>
    //   <Document>{pages}</Document>
    // </PDFViewer>
    <PDFDownloadLink
      style={{
        textDecoration: "none",
      }}
      document={<Document>{pages}</Document>}
      fileName={`${props.username}(${props.name})`}
    >
      {({ blob, url, loading, error }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ color: Dark.text }}>DOWNLOAD PDF</div>
        </div>
      )}
    </PDFDownloadLink>
  );
};

export default PdfDocument;
