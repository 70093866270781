import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import "./Symbol.css";
import Loading from "react-loading";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
const backend = new Backend();

export default function SymbolCard(props) {
  const item = props.item;

  const [loading, setLoading] = useState(false);
  const [brokerage, setBrokerage] = useState("");

  const UpdateSymbol = () => {
    if (brokerage < 0) {
      toast.error("Invalid Brokrage Amount ", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        username: localStorage.getItem("username"),
        server: localStorage.getItem("server"),
        client_id: props.client_id,
        symbol: item.symbol,
        brokerage: brokerage,
      };

      backend.symbol_brokerage_update(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          props.loadSymbol();
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  return (
    <div
      style={{
        margin: 10,
        padding: 10,
        backgroundColor: Dark.background,
        height: 55,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          color: Dark.text,
          fontSize: 15,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {item.symbol} : {item.brokerage}
      </div>
    </div>
  );
}
