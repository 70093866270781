import React, { useState } from "react";
import { Dark } from "../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import useKeypress from "react-use-keypress";
import "./Password.css";
export default function TPassword(props) {
  const [password, setPassword] = useState("");

  useKeypress("Enter", () => {
    props.Update();
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: 45,
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        ESC TO CLOSE
      </div>

      <div
        style={{
          height: 80,
          margin: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loginInputTpass">
          <InputText
            autoComplete="off"
            type="password"
            autocomplete="new-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              props.tpassword(e.target.value);
            }}
            autoFocus={true}
            placeholder="Enter Transaction Password"
          />
        </div>
      </div>
      <div
        onClick={props.Update}
        style={{
          height: 45,
          backgroundColor: Dark.primary,
          margin: 10,
          width: "80%",
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        SUBMIT {props.text}
      </div>
    </div>
  );
}
