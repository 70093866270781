import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../Theme/Theme";
import Logo from "../../../Image/Logo.png";
import LogoSub from "../../../Image/LogoSub.png";
import { BiLogOut, BiUserCircle } from "react-icons/bi";
import { Dialog } from "primereact/dialog";
import "./Topview.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Profile from "./Profile/Profile";
import Ledger from "./Ledger/Ledger";
import { toast } from "react-toast";
import Backend from "../../../Backend/Backend";
const backend = new Backend();

export default function TopView() {
  const path = window.location.pathname;
  const navigation = useNavigate();
  const theme = useSelector((state) => state.theme);

  const [select, setSelect] = useState(false);
  const [profile, setProfile] = useState(false);


  const [ledger, setLedger] = useState(false);

  const [checked, setChecked] = useState(1);

  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("username");
    localStorage.removeItem("server");
    localStorage.removeItem("AuthState");
    window.location = "/";
  };

  onkeydown = (event) => {
    if (event.shiftKey == true) {
      if (event.key == "e" || event.key == "E") {
        event.preventDefault();
        navigation("/");
      }

      if (event.key == "w" || event.key == "W") {
        event.preventDefault();
        navigation("/Watchlist");
      }

      if (event.key == "c" || event.key == "C") {
        event.preventDefault();
        navigation("/Client");
      }

      if (event.key == "s" || event.key == "S") {
        event.preventDefault();
        navigation("/Position");
      }

      if (event.key == "t" || event.key == "T") {
        event.preventDefault();
        navigation("/Trade");
      }

      if (event.key == "p" || event.key == "P") {
        event.preventDefault();
        navigation("/Pending");
      }

      if (event.key == "h" || event.key == "H") {
        event.preventDefault();
        navigation("/Holding");
      }

      if (event.key == "r" || event.key == "R") {
        event.preventDefault();
        navigation("/Report");
      }


      if (event.ctrlKey == true) {
        if (event.key == "t" || event.key == "T") {
          event.preventDefault();
          navigation("/PositionTrade");
        }

        if (event.key == "c" || event.key == "C") {
          event.preventDefault();
          navigation("/PositionClient");
        }

        if (event.key == "p" || event.key == "P") {
          event.preventDefault();
          navigation("/ParkingPosition");
        }

        if (event.key == "b" || event.key == "B") {
          event.preventDefault();
          navigation("/Brokerage");
        }
      }
    }
  };

  return (
    <div
      key={select}
      className="topview"
      style={{ height: 100, backgroundColor: Dark.background, display: "flex" }}
    >
      <div
        onClick={() => {
          setSelect(!select);
          navigation("/");
        }}
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img src={Logo} style={{ height: "70px" }} />
        <img src={LogoSub} style={{ height: "30px" }} />
      </div>
      <div
        style={{
          flex: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            setSelect(!select);
            navigation("/");
          }}
          className="link"
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor:
              path == "/" || path == "/Home" ? Dark.primary : Dark.background,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 7,
          }}
        >
          <div>HOM</div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            E
          </div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Watchlist");
          }}
          className="link"
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor:
              path == "/Watchlist" ? Dark.primary : Dark.background,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 7,
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            W
          </div>
          <div>ATCHLIST</div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Client");
          }}
          className="link"
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor: path == "/Client" ? Dark.primary : Dark.background,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 7,
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            C
          </div>
          <div>LIENT</div>
        </div>

        <div class="dropdown">
          <button
            class="dropbtn"
            style={{
              backgroundColor:
                path == "/PositionTrade" ||
                path == "/Position" ||
                path == "/ParkingPosition" ||
                path == "/PositionClient"
                  ? Dark.primary
                  : Dark.background,
              display: "flex",
            }}
          >
            <div>PO</div>
            <div
              style={{
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 2,
                borderBottomColor: Dark.sell,
              }}
            >
              S
            </div>
            <div>ITION</div>
          </button>
          <div class="dropdown-content">
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/PositionTrade");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: Dark.sell,
                }}
              >
                T
              </div>
              <div>RADE WISE</div>
            </div>
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/Position");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: Dark.sell,
                }}
              >
                P
              </div>
              <div>OSITION WISE</div>
            </div>
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/PositionClient");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: Dark.sell,
                }}
              >
                C
              </div>
              <div>LIENT WISE</div>
            </div>

            <div
              onClick={() => {
                setSelect(!select);
                navigation("/ParkingPosition");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: Dark.sell,
                }}
              >
                P
              </div>
              <div>ARKING POSITION</div>
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Trade");
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor: path == "/Trade" ? Dark.primary : Dark.background,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 7,
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            T
          </div>
          <div>RADE</div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Pending");
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            padding: 10,
            backgroundColor:
              path == "/Pending" ? Dark.primary : Dark.background,
            borderRadius: 7,
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            P
          </div>
          <div>ENDING</div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Holding");
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor:
              path == "/Holding" ? Dark.primary : Dark.background,
            borderRadius: 7,
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            H
          </div>
          <div>OLDING</div>
        </div>

        <div class="dropdown">
          <button
            class="dropbtn"
            style={{
              backgroundColor:
                path == "/Report" || path == "/Brokerage"
                  ? Dark.primary
                  : Dark.background,
              display: "flex",
            }}
          >
            <div
              style={{
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 2,
                borderBottomColor: Dark.sell,
              }}
            >
              R
            </div>
            <div>EPORT</div>
          </button>
          <div class="dropdown-content">
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/Report");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: Dark.sell,
                }}
              >
                C
              </div>
              <div>LIENT</div>
            </div>
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/Brokerage");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: Dark.sell,
                }}
              >
                B
              </div>
              <div>ROKERAGE</div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="dropdownprofile">
          <button class="dropbtnprofile">
            {" "}
            <BiUserCircle
              color={theme == 0 ? Dark.text : Light.text}
              fontSize={30}
            />{" "}
          </button>
          <div class="dropdown-contentprofile">
            <div
              onClick={() => {
                setSelect(!select);
                setProfile(true);
              }}
              className="link"
            >
              PROFILE
            </div>
            <div
              onClick={() => {
                setSelect(!select);
                setLedger(true);
              }}
              className="link"
            >
              LEDGER
            </div>
          </div>
        </div>

        <div onClick={Logout} style={{ marginLeft: 20 }}>
          <BiLogOut color={Dark.text} fontSize={30} />
        </div>
      </div>

      <Dialog
        showHeader={false}
        visible={profile}
        style={{
          width: "40vw",
          height: "55vh",
        }}
        onHide={() => setProfile(false)}
      >
        <Profile />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={ledger}
        className="modalpo"
        style={{ width: "50vw", height: "68vh" }}
        onHide={() => setLedger(false)}
      >
        <Ledger />
      </Dialog>
    </div>
  );
}
