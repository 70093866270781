import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import "./Future.css";

export default function FutureCard(props) {
  const i = props.item;
  return (
    <div
      style={{
        height: 80,
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0.2,
        borderBottomColor: Dark.primary,
        display: "flex",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.symbol}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 14, marginBottom: 7 }}>
          {parseFloat(i.min_qty).toFixed(2)}
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 14, marginBottom: 7 }}>
          {parseFloat(i.qty_per_order).toFixed(2)}
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 14, marginBottom: 7 }}>
          {parseFloat(i.total_quantity).toFixed(2)}
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >

      </div>
    </div>
  );
}
