import React, { useContext } from "react";
import { Dark } from "../../../../Theme/Theme";
import { BsFilePdf } from "react-icons/bs";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Pdf from "./Pdf/Pdf";
import { useDispatch, useSelector } from "react-redux";
import SocketContext from "../../../../Healer/SocketContect";
export default function TopCard(props) {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const total = parseFloat(useSelector((state) => state.total)).toFixed(0);

  let futurebuy = parseFloat(
    props.futurebuy == null ? 0 : props.futurebuy
  ).toFixed(0);
  let futuresell = parseFloat(
    props.futuresell == null ? 0 : props.futuresell
  ).toFixed(0);
  let future = parseFloat(props.future == null ? 0 : props.future).toFixed(0);

  let mcxbuy = parseFloat(props.mcxbuy == null ? 0 : props.mcxbuy).toFixed(0);
  let mcxsell = parseFloat(props.mcxsell == null ? 0 : props.mcxsell).toFixed(
    0
  );
  let mcx = parseFloat(props.mcx == null ? 0 : props.mcx).toFixed(0);

  let optionsbuy = parseFloat(
    props.optionsbuy == null ? 0 : props.optionsbuy
  ).toFixed(0);
  let optionssell = parseFloat(
    props.optionssell == null ? 0 : props.optionssell
  ).toFixed(0);
  let options = parseFloat(props.options == null ? 0 : props.options).toFixed(
    0
  );

  let buy = parseFloat(props.buy == null ? 0 : props.buy).toFixed(0);
  let sell = parseFloat(props.sell == null ? 0 : props.sell).toFixed(0);
  let totalvol = parseFloat(props.total == null ? 0 : props.total).toFixed(0);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: Dark.background,
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "50px",
            width: "100px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 14 }}>M2M</div>
          <div
            style={{
              color: total > 0 ? Dark.buy : Dark.sell,
              fontSize: 14,
              paddingTop: 5,
            }}
          >
            {total}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            props.Filter("TOTAL");
          }}
          style={{
            height: "60px",
            width: "270px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.buy, fontSize: 12 }}>BUY</div>
            <div
              style={{
                color: Dark.buy,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {buy}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, fontSize: 12 }}>TOTAL</div>
            <div
              style={{
                color: Dark.text,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {totalvol}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.sell, fontSize: 12 }}>SELL</div>
            <div
              style={{
                color: Dark.sell,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {sell}
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            props.Filter("FUTURE");
          }}
          style={{
            height: "60px",
            width: "270px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.buy, fontSize: 12 }}>BUY</div>
            <div
              style={{
                color: Dark.buy,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {futurebuy}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, fontSize: 12 }}>FUTURE</div>
            <div
              style={{
                color: Dark.text,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {future}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.sell, fontSize: 12 }}>SELL</div>
            <div
              style={{
                color: Dark.sell,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {futuresell}
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            props.Filter("MCX");
          }}
          style={{
            height: "60px",
            width: "270px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.buy, fontSize: 12 }}>BUY</div>
            <div
              style={{
                color: Dark.buy,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {mcxbuy}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, fontSize: 12 }}>MCX</div>
            <div
              style={{
                color: Dark.text,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {mcx}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.sell, fontSize: 12 }}>BUY</div>
            <div
              style={{
                color: Dark.sell,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {mcxsell}
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            props.Filter("OPTIONS");
          }}
          style={{
            height: "60px",
            width: "270px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.buy, fontSize: 12 }}>BUY</div>
            <div
              style={{
                color: Dark.buy,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {optionsbuy}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, fontSize: 12 }}>OPTIONS</div>
            <div
              style={{
                color: Dark.text,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {options}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.sell, fontSize: 12 }}>SELL</div>
            <div
              style={{
                color: Dark.sell,
                marginTop: 10,
                fontSize: 16,
              }}
            >
              {optionssell}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "60px",
            width: "50px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              color: Dark.text,
            }}
          >
            <PDFDownloadLink
              style={{
                textDecoration: "none",
              }}
              document={
                <Pdf
                  holding={props.holding}
                  dispatch={dispatch}
                  total={total}
                  socket={socket}
                  future={future}
                  futurebuy={futurebuy}
                  futuresell={futuresell}
                  mcx={mcx}
                  mcxbuy={mcxbuy}
                  mcxsell={mcxsell}
                  options={options}
                  optionsbuy={optionsbuy}
                  optionssell={optionssell}
                  totalvol={totalvol}
                  buy={buy}
                  sell={sell}
                />
              }
              fileName={"Holding"}
            >
              {({ blob, url, loading, error }) => (
                <div style={{ color: Dark.text }}>
                  <BsFilePdf size={30} color={Dark.text} />
                </div>
              )}
            </PDFDownloadLink>
          </div>
        </div>
      </div>
    </div>
  );
}
