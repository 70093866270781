import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import moment from "moment";
export default function LedgerCard(props) {
  const i = props.item;
  return (
    <div
      style={{
        height: 40,
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0.2,
        borderBottomColor: Dark.primary,
        display: "flex",
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.remarks}
      </div>
      <div
        style={{
          color:
            i.deposit == 0 ? Dark.sell : i.withdraw == 0 ? Dark.buy : Dark.buy,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.deposit == 0
          ? parseFloat(i.withdraw).toFixed(2)
          : i.withdraw == 0
          ? parseFloat(i.deposit).toFixed(2)
          : parseFloat(i.weekly_amt).toFixed(2)}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {moment(i.date_created).format("DD-MM-YYYY hh:mm:ss")}
      </div>
      {props.ledger.length > 0 ? (
        <div
          style={{
            color: Dark.text,
            flex: 0.4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
      ) : null}
    </div>
  );
}
