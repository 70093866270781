import { View, Text } from "@react-pdf/renderer";
import React from "react";
import { Dark } from "../../../../../Theme/Theme";

export default function TotalCard(props) {
  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: Dark.buy,
            padding: 10,
            backgroundColor: Dark.background,
            borderRadius: 10,
          }}
        >
          BUY VOLUME :{" "}
          {props.buy_volume == null
            ? 0
            : parseFloat(props.buy_volume).toFixed(0)}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: Dark.sell,
            padding: 10,
            backgroundColor: Dark.background,
            borderRadius: 10,
          }}
        >
          SELL VOLUME :{" "}
          {props.sell_volume == null
            ? 0
            : parseFloat(props.sell_volume).toFixed(0)}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: Dark.sell,
            padding: 10,
            backgroundColor: Dark.background,
            borderRadius: 10,
          }}
        >
          BROKERAGE :{" "}
          {props.brokerage == null ? 0 : parseFloat(props.brokerage).toFixed(0)}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: props.m2m > 0 ? Dark.buy : Dark.sell,
            padding: 10,
            backgroundColor: Dark.background,
            borderRadius: 10,
          }}
        >
          PROFIT LOSS :{" "}
          {props.m2m == null ? 0 : parseFloat(props.m2m).toFixed(0)}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: props.profit_loss > 0 ? Dark.buy : Dark.sell,
            padding: 10,
            backgroundColor: Dark.background,
            borderRadius: 10,
          }}
        >
          NET AMOUNT :{" "}
          {props.profit_loss == null
            ? 0
            : parseFloat(props.profit_loss).toFixed(0)}
        </Text>
      </View>
    </View>
  );
}
