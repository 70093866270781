import React from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
export default function TradeCard(props) {
  const item = props.item;

  return (
    <div
      style={{
        height: 45,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>{item.symbol}</div>
        <div style={{ fontSize: 10, paddingTop: 2 }}>
          {moment(item.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.buy_sell == 0 ? "BUY" : "SELL"}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.qty}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {item.rate}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {parseFloat(item.brokerage_client_amt).toFixed(2)}(
        {item.brokerage_client})
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {parseFloat(item.brokerage_broker_amt).toFixed(2)}(
        {item.brokerage_broker})
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {parseFloat(item.profit_loss).toFixed(0)}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 10,
          flexDirection: "column",
        }}
      >
        <div>{moment(item.date_created).format("DD-MM-YYYY")}</div>
        <div style={{ paddingTop: 2, fontSize: 8 }}>
          {moment(item.date_created).format("HH:mm:ss")}
        </div>
      </div>
    </div>
  );
}
