import React, { useState } from "react";
import WatchlistCard from "../WatchlistCard";
import { Dark } from "../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import BuySellCard from "../BuySellCard/BuySellCard";
export default function Future(props) {
  const height = window.innerHeight;
  const [modal, setModal] = useState("");
  const [symbol, setSymbol] = useState("");

  const open = (a) => {
    setSymbol(a);
    setModal(true);
  };

  return (
    <div style={{ margin: 10, height: height / 1.4 }}>
      {props.fut.length > 0 ? (
        <div
          className="scroll"
          style={{
            height: "100%",
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            overflowY: "scroll",
          }}
        >
          {props.fut.map((i, t) => {
            return (
              <WatchlistCard
                item={i}
                open={open}
                permission={props.permission}
              />
            );
          })}
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
            {" "}
            Nothing here
          </div>
          <div style={{ color: Dark.text }}>
            Use the search bar to add instruments.
          </div>
        </div>
      )}

      <Dialog
        showHeader={false}
        visible={modal}
        style={{ width: "60vw", height: "60vh" }}
        onHide={() => {
          props.load();
          setModal(false);
        }}
      >
        <BuySellCard symbol={symbol} symbol_type={"fut"} />
      </Dialog>
    </div>
  );
}
