import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SocketContect from "./Component/Healer/SocketContect";

import Notification from "./Component/Healer/Notification/Notification";

import NiftyBankNifty from "./Component/Screen/MainStack/NiftyBankNifty/NiftyBankNifty";

import TopView from "./Component/Screen/MainStack/TopView/TopView";

import { Dark } from "./Component/Theme/Theme";
import { ToastContainer } from "react-toast";
import Login from "./Component/Screen/LoginStack/Login/Login";

import Home from "./Component/Screen/MainStack/Home/Home";
import Watchlist from "./Component/Screen/MainStack/Watchlist/Watchlist";

import Client from "./Component/Screen/MainStack/Client/Client";

import Position from "./Component/Screen/MainStack/Position/Position";
import PositionClient from "./Component/Screen/MainStack/PositionClient/PositionClient";
import ParkingPosition from "./Component/Screen/MainStack/ParkingPosition/ParkingPosition";
import PositionTrade from "./Component/Screen/MainStack/PositionTrade/PositionTrade";

import Trade from "./Component/Screen/MainStack/Trade/Trade";
import SortTrade from "./Component/Screen/MainStack/SortTrade/SortTrade";
import Pending from "./Component/Screen/MainStack/Pending/Pending";
import Holding from "./Component/Screen/MainStack/Holding/Holding";

import ClientReport from "./Component/Screen/MainStack/Report/Client";

import Brokerage from "./Component/Screen/MainStack/Brokerage/Brokerage";

import useWindowSize from "./Component/Healer/WindowSize";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./App.css";

import "react-confirm-alert/src/react-confirm-alert.css";
import useSize from "./Component/Utils/useSize";
import io from "socket.io-client";


var socket = io("https://sauda.io:40000");

let data = {
  token: localStorage.getItem("token"),
  id: localStorage.getItem("id"),
  server: localStorage.getItem("server"),
};

socket.emit("brokeractive", data);

export default function App() {
  const windowSize = useSize();

  return (
    <div style={{ backgroundColor: Dark.secondary, height: windowSize }}>
      <ToastContainer className="toplevel" position="top-right" delay={3000} />
      {localStorage.getItem("AuthState") == "1" ? (
        <BrowserRouter>
          <SocketContect.Provider value={socket}>
            <Notification />
            <NiftyBankNifty />
            <TopView />
            <div>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Home />} />
                <Route path="/Home" element={<Home />} />
                <Route path="/Watchlist" element={<Watchlist />} />
                <Route path="/Client" element={<Client />} />
                <Route path="/Position" element={<Position />} />
                <Route path="/PositionClient" element={<PositionClient />} />
                <Route path="/ParkingPosition" element={<ParkingPosition />} />
                <Route path="/PositionTrade" element={<PositionTrade />} />
                <Route path="/Trade" element={<Trade />} />
                <Route path="/SortTrade" element={<SortTrade />} />
                <Route path="/Pending" element={<Pending />} />
                <Route path="/Holding" element={<Holding />} />
                <Route path="/Report" element={<ClientReport />} />
                <Route path="/Brokerage" element={<Brokerage />} />
              </Routes>
            </div>
          </SocketContect.Provider>
        </BrowserRouter>
      ) : (
        <div>
          <Login />
        </div>
      )}
    </div>
  );
}
