import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
const backend = new Backend();
export default function Margin(props) {
  const client = props.client;

  const [clientS, setClientS] = useState("");
  const [masterS, setMasterS] = useState("");

  const [loading, setLoading] = useState(false);

  const [multiplier_future, setMultiplierFuture] = useState("");
  const [multiplier_mcx, setMultiplierMcx] = useState("");
  const [multiplier_options, setMultiplierOptions] = useState("");

  const [fatak, setFatak] = useState("");

  useEffect(() => {
    loadClientSingle();
  }, []);

  const loadClientSingle = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client.id,
    };

    backend.single_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClientS(r.client);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };



  return (
    <div
      key={[
        clientS.multiplier_future,
        clientS.multiplier_mcx,
        clientS.multiplier_options,
        clientS.deposit_stop,
      ]}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Future Margin</div>
            <div>{clientS.multiplier_future}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={multiplier_future}
              onChange={(e) => {
                setMultiplierFuture(e.target.value);
              }}
              type="number"
              placeholder="Future"
            />
          </div>

          <div
            style={{
              color: Dark.text,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Max Future Margin</div>
            <div>{masterS.multiplier_future}</div>
          </div>
        </div>

        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Mcx Margin</div>
            <div>{clientS.multiplier_mcx}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={multiplier_mcx}
              onChange={(e) => {
                setMultiplierMcx(e.target.value);
              }}
              type="number"
              placeholder="Mcx"
            />
          </div>

          <div
            style={{
              color: Dark.text,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Max Mcx Margin</div>
            <div>{masterS.multiplier_mcx}</div>
          </div>
        </div>

        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Options Margin</div>
            <div>{clientS.multiplier_options}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={multiplier_options}
              onChange={(e) => {
                setMultiplierOptions(e.target.value);
              }}
              type="number"
              placeholder="Options"
            />
          </div>

          <div
            style={{
              color: Dark.text,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Max Options Margin</div>
            <div>{masterS.multiplier_options}</div>
          </div>
        </div>

        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>FATAK</div>
            <div>{clientS.deposit_stop}%</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={fatak}
              onChange={(e) => {
                setFatak(e.target.value);
              }}
              type="number"
              placeholder="FATAK"
            />
          </div>
        </div>
      </div>

    </div>
  );
}
