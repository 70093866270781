import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";

export default function Total(props) {
  const trade = props.trade;

  const [total, setTotal] = useState(0);
  const [buy, setBuy] = useState(0);
  const [sell, setSell] = useState(0);

  const [fut, setFut] = useState(0);
  const [mcx, setMcx] = useState(0);
  const [fo, setFo] = useState(0);

  useEffect(() => {
    Filter();
  }, [trade]);

  const Filter = () => {
    if (trade.length > 0) {
      var buy = trade.filter((x) => x.buy_sell == 0);
      var sell = trade.filter((x) => x.buy_sell == 1);
      var fut = trade.filter((x) => x.script_type == "fut");
      var mcx = trade.filter((x) => x.script_type == "mcx");
      var fo = trade.filter((x) => x.script_type == "fo");

      setBuy(buy.length);
      setSell(sell.length);
      setTotal(trade.length);
      setFut(fut.length);
      setMcx(mcx.length);
      setFo(fo.length);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: 45,
        backgroundColor: Dark.primary,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.text,
          }}
        >
          TOTAL : {total}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.buy,
          }}
        >
          BUY : {buy}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.sell,
          }}
        >
          SELL : {sell}
        </div>
      </div>

      {fut > 0 ? (
        <div
          style={{
            flex: 1,
            height: "45px",
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: 10,
              backgroundColor: Dark.background,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              color: Dark.text,
            }}
          >
            FUTURE : {fut}
          </div>
        </div>
      ) : null}

      {mcx > 0 ? (
        <div
          style={{
            flex: 1,
            height: "45px",
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: 10,
              backgroundColor: Dark.background,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              color: Dark.text,
            }}
          >
            MCX {mcx}
          </div>
        </div>
      ) : null}

      {fo > 0 ? (
        <div
          style={{
            flex: 1,
            height: "45px",
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: 10,
              backgroundColor: Dark.background,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              color: Dark.text,
            }}
          >
            OPTIONS {fo}
          </div>
        </div>
      ) : null}
    </div>
  );
}
