import React from "react";
import { Dark } from "../../../Theme/Theme";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
export default function Menu(props) {
  return (
    <div
      style={{
        height: 30,
        display: "flex",
        backgroundColor: Dark.primary,
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Symbol</div>
        <div onClick={() => props.sort("SymbolUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("SymbolDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Buy-Qty</div>
        <div onClick={() => props.sort("BuyQtyUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("BuyQtyDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Buy-Avg</div>
        <div onClick={() => props.sort("BuyAvgUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("BuyAvgDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Sell-Qty</div>
        <div onClick={() => props.sort("SellQtyUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("SellQtyDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Sell-Avg</div>
        <div onClick={() => props.sort("SellAvgUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("SellAvgDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Net-Qty</div>
        <div onClick={() => props.sort("NetUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("NetDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Broker-Qty</div>
        <div onClick={() => props.sort("BQtyUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("BQtyDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Live Rate
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        M2M
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
}
