import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import TopCard from "./TopCard/TopCard";
import Menu from "./Menu";
import TradeCard from "./TradeCard/TradeCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
const backend = new Backend();

export default function SortTrade() {
  const [loading, setLoading] = useState(false);
  const [trade, setTrade] = useState([]);

  const [buy_volume, setbuy_volume] = useState(0);
  const [sell_volume, setsell_volume] = useState(0);
  const [brokerage, setBrokerage] = useState(0);
  const [profit_loss, setProfit_loss] = useState(0);
  const [client_id, setClientId] = useState("");
  const [symbol, setSymbol] = useState("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  useEffect(() => {
    loadSortTrade();
  }, []);

  const loadSortTrade = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      start: start,
      end: end,
    };

    backend.load_sort_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setTrade(r.trade);
        setBrokerage(r.brokerage);
        setProfit_loss(r.profit_loss);
        setbuy_volume(r.buy_volume);
        setsell_volume(r.sell_volume);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadClientTrade = (a) => {
    setClientId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: a,
      symbol: symbol,
      start: start,
      end: end,
    };
    backend.load_client_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setTrade(r.trade);
        setBrokerage(r.brokerage);
        setProfit_loss(r.profit_loss);
        setbuy_volume(r.buy_volume);
        setsell_volume(r.sell_volume);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadSymbolTrade = (a) => {
    setSymbol(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client_id,
      symbol: a,
      start: start,
      end: end,
    };
    backend.load_symbol_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setTrade(r.trade);
        setBrokerage(r.brokerage);
        setProfit_loss(r.profit_loss);
        setbuy_volume(r.buy_volume);
        setsell_volume(r.sell_volume);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadFilterTrade = (a, b) => {
    setStart(a);
    setEnd(b);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client_id,
      symbol: symbol,
      start: a,
      end: b,
    };
    backend.load_sort_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setTrade(r.trade);
        setBrokerage(r.brokerage);
        setProfit_loss(r.profit_loss);
        setbuy_volume(r.buy_volume);
        setsell_volume(r.sell_volume);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "82vh",
        flexDirection: "column",
      }}
    >
      <TopCard
        trade={trade}
        buy_volume={buy_volume}
        sell_volume={sell_volume}
        brokerage={brokerage}
        profit_loss={profit_loss}
        start={start}
        end={end}
        client_select={client_id}
        symbol_select={symbol}
        reload={loadSortTrade}
        client={loadClientTrade}
        symbol={loadSymbolTrade}
        filter={loadFilterTrade}
      />
      <div style={{ backgroundColor: Dark.background }}>
        <Menu />
        <div className="scroll" style={{ height: "66vh", overflowY: "scroll" }}>
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" color={Dark.text} height={35} width={35} />
            </div>
          ) : trade.length > 0 ? (
            trade.map((i, t) => {
              return <TradeCard item={i} index={t} load={loadSortTrade} />;
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Trade Report Found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
