import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Menu from "./Menu";
import ClientCard from "./ClientCard/ClientCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import TopCard from "./TopCard/TopCard";
import Loading from "react-loading";
const backend = new Backend();

export default function Client() {
  const [loading, setLoading] = useState(false);

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [brokerage, setBrokerage] = useState(0);
  const [pl, setPl] = useState(0);
  const [amount, setAmount] = useState(0);

  const [futurepl, setFuturePl] = useState(0);
  const [futurebrok, setFutureBrok] = useState(0);
  const [futurevol, setFutureVol] = useState(0);
  const [mcxpl, setMcxPl] = useState(0);
  const [mcxbrok, setMcxBrok] = useState(0);
  const [mcxvol, setMcxVol] = useState(0);
  const [optionspl, setOptionsPl] = useState(0);
  const [optionsbrok, setOptionsBrok] = useState(0);
  const [optionsvol, setOptionsVol] = useState(0);
  const [client, setClient] = useState([]);
  const [clientsearch, setClientSearch] = useState([]);

  useEffect(() => {
    loadClientBillFilter();
  }, []);

  const loadClientBillFilter = (a, b) => {
    setStart(a);
    setEnd(b);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      start: a == undefined ? new Date() : a,
      end: b == undefined ? new Date() : b,
    };

    backend.client_report(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClient(r.client);
        setClientSearch(r.client);
        setBrokerage(r.brokerage);
        setPl(r.profit_loss);
        setAmount(r.amount);

        setFuturePl(r.profit_loss_future);
        setFutureBrok(r.brokerage_future);
        setFutureVol(r.amount_future);

        setMcxPl(r.profit_loss_mcx);
        setMcxBrok(r.brokerage_mcx);
        setMcxVol(r.amount_mcx);

        setOptionsPl(r.profit_loss_options);
        setOptionsBrok(r.brokerage_options);
        setOptionsVol(r.amount_options);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ height: "82vh" }}>
      <div
        style={{
          display: "flex",
          height: "82vh",
        }}
      >
        <div
          style={{ flex: 5, backgroundColor: Dark.background, height: "100%" }}
        >
          <TopCard
            start={start}
            end={end}
            brokerage={brokerage}
            pl={pl}
            amount={amount}
            futurepl={futurepl}
            futurebrok={futurebrok}
            futurevol={futurevol}
            mcxpl={mcxpl}
            mcxbrok={mcxbrok}
            mcxvol={mcxvol}
            optionspl={optionspl}
            optionsbrok={optionsbrok}
            optionsvol={optionsvol}
            filter={loadClientBillFilter}
          />
          <Menu />
          <div
            className="scroll"
            style={{ height: "75%", overflowY: "scroll" }}
          >
            {loading ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading type="spin" height={35} width={35} color={Dark.text} />
              </div>
            ) : client.length > 0 ? (
              client.map((i, t) => {
                return <ClientCard item={i} index={t} />;
              })
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  textTransform:"uppercase"
                }}
              >
                <div
                  style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}
                >
                  {" "}
                  Nothing here
                </div>
                <div style={{ color: Dark.text }}>No Report Found.</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
