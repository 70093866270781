import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Menu from "./Menu";
import PositionCard from "./PositionCard/PositionCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";

const backend = new Backend();

export default function PositionClient() {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(false);
  const [client, setClient] = useState([]);
  const [clientsearch, setClientSearch] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    loadStanding();
  }, []);

  const loadStanding = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_standing_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClient(r.client);
        setClientSearch(r.client);
        setUpdate(!update);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };


  const sort = (s) => {
    setKey(!key);

    let data;

    if (s == "ClientUp") {
      data = clientsearch.sort((a, b) =>
        a.client_username > b.client_username ? 1 : -1
      );
    } else if (s == "ClientDown") {
      data = clientsearch.sort((a, b) =>
        a.client_username < b.client_username ? 1 : -1
      );
    } else if (s == "CreditUp") {
      data = clientsearch.sort((a, b) => (a.deposit_total > b.deposit_total ? 1 : -1));
    } else if (s == "CreditDown") {
      data = clientsearch.sort((a, b) => (a.deposit_total < b.deposit_total ? 1 : -1));
    } else if (s == "CBalanceUp") {
      data = clientsearch.sort((a, b) => (a.deposit_current > b.deposit_current ? 1 : -1));
    } else if (s == "CBalanceDown") {
      data = clientsearch.sort((a, b) => (a.deposit_current < b.deposit_current ? 1 : -1));
    } else if (s == "BookedUp") {
      data = clientsearch.sort((a, b) => (a.profit_loss_week > b.profit_loss_week ? 1 : -1));
    } else if (s == "BookedDown") {
      data = clientsearch.sort((a, b) => (a.profit_loss_week < b.profit_loss_week ? 1 : -1));
    }else {
      setClient([])
    }

    setClient(data);
  };


  return (
    <div
    key={key}
      style={{
        display: "flex",
        height: "82vh",
        flexDirection: "column",
      }}
    >
      <div style={{ backgroundColor: Dark.background }}>
        <Menu sort={sort}  />
        <div
          className="scroll"
          style={{ height: "78vh", overflowY: "scroll" }}
        >
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" height={35} width={35} color={Dark.text} />
            </div>
          ) : client.length > 0 ? (
            client.map((i, t) => {
              return (
                <PositionCard
                  item={i}
                  load={loadStanding}
                />
              );
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textTransform: "uppercase",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Active Posistion Found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
