import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import "./Active.css";

export default function Active(props) {
  return (
    <div>
      <div
        key={props.status}
        style={{
          padding: 10,
          backgroundColor: props.status == 0 ? Dark.sell : Dark.buy,
          paddingLeft: 20,
          paddingRight: 20,
          borderRadius: 7,
          color: Dark.text,
        }}
      >
        Only SqOff
      </div>
    </div>
  );
}
