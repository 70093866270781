import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

export default function Right(props) {
  const total = parseFloat(useSelector((state) => state.total)).toFixed(0);
  const btotal = parseFloat(useSelector((state) => state.btotal)).toFixed(0);
  let m2m = parseFloat(parseFloat(total) + parseFloat(btotal)).toFixed(0);
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          height: "60px",
          width: "140px",
          backgroundColor: Dark.secondary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 10,
          marginRight: 10,
        }}
      >
        <div style={{ color: Dark.text, fontSize: 14 }}>Profit/Loss</div>
        <div
          style={{
            color: total > 0 ? Dark.buy : Dark.sell,
            marginTop: 10,
            fontSize: 16,
          }}
        >
          <NumericFormat
            value={total}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>
      <div
        style={{
          height: "60px",
          width: "120px",
          backgroundColor: Dark.secondary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 10,
          marginRight: 10,
        }}
      >
        <div style={{ color: Dark.text, fontSize: 14 }}>M2M</div>
        <div
          style={{
            color: m2m > 0 ? Dark.buy : Dark.sell,
            marginTop: 10,
            fontSize: 16,
          }}
        >
          <NumericFormat
            value={m2m}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>
      <div
        style={{
          height: "60px",
          width: "120px",
          backgroundColor: Dark.secondary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginRight: 10,
          borderRadius: 10,
        }}
      >
        <div style={{ color: Dark.sell, fontSize: 14 }}>BROKERAGE</div>
        <div style={{ color: Dark.sell, marginTop: 10, fontSize: 16 }}>
          <NumericFormat
            value={btotal}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>
    </div>
  );
}
