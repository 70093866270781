import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import moment from "moment";
import { View, Text } from "@react-pdf/renderer";

export default function ClientCard(props) {
  const item = props.item;
  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>{item.server} </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: Dark.text,
          }}
        >
          {item.name}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 12, color: Dark.text }}>{item.username}</Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 12, color: Dark.text }}>
          {item.deposit_current}
        </Text>
      </View>
      <View
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 8, color: Dark.text }}>
          F : {item.multiplier_future}, M : {item.multiplier_mcx}, O :{" "}
          {item.multiplier_options}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: item.status == 0 ? Dark.sell : Dark.buy,
            textAlign: "center",
          }}
        >
          {item.status == 0 ? " Deactive" : " Active"}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: item.future == 0 ? Dark.sell : Dark.buy,
            textAlign: "center",
          }}
        >
          {item.future == 0 ? " Deactive" : " Active"}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: item.mcx == 0 ? Dark.sell : Dark.buy,
            textAlign: "center",
          }}
        >
          {item.mcx == 0 ? " Deactive" : " Active"}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: item.options == 0 ? Dark.sell : Dark.buy,
            textAlign: "center",
          }}
        >
          {item.options == 0 ? " Deactive" : " Active"}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: item.fresh_limit == 0 ? Dark.sell : Dark.buy,
            textAlign: "center",
          }}
        >
          {item.fresh_limit == 0 ? " Deactive" : " Active"}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: item.mid_limit == 0 ? Dark.sell : Dark.buy,
            textAlign: "center",
          }}
        >
          {item.mid_limit == 0 ? " Deactive" : " Active"}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: Dark.text,
            textAlign: "center",
          }}
        >
          {moment(item.date_created).format("DD-MM-YYYY")}
        </Text>
      </View>
    </View>
  );
}
