import { View, Text } from "@react-pdf/renderer";
import React from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
export default function PdfCard(props) {
  const item = props.item;

  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0.5,
        borderBottomColor: Dark.text,
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {item.symbol_display}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>{item.min_qty == null ? 0 : item.min_qty}</Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {item.qty_per_order}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {item.total_quantity}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {item.lot_size == null ? 0 : item.lot_size}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {moment(item.expiry_date).format("DD-MM-YYYY HH:mm:ss")}
        </Text>
      </View>
    </View>
  );
}
