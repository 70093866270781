import React, { useContext } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import Menu from "./Menu";
import { Dark } from "../../../../Theme/Theme";
import PdfCard from "./PdfCard";
import "./Pdf.css";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#000",
    display: "flex",
  },
  sectionheader: {
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Dark.primary,
  },
  sectionfooter: {
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Dark.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  section1: {
    margin: 10,
    padding: 10,
    backgroundColor: Dark.secondary,
    borderRadius: 10,
  },
});

// Create Document Component
const Pdf = (props) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Menu />
        {props.data.length > 0
          ? props.data.map((i) => {
              return <PdfCard item={i} />;
            })
          : null}
      </Page>
    </Document>
  );
};

export default Pdf;
