import React from "react";
import { Dark } from "../../../../Theme/Theme";
import TradeCard from "./TradeCard";

export default function Trade(props) {
  return (
    <div>
      <div
        style={{
          height: 40,
          display: "flex",
          backgroundColor: Dark.background,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          SYMBOL
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          B/S
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          QTY
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          RATE
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          BROKERAGE
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          BROKER
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          P/L
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          DATE
        </div>
      </div>
      <div className="scroll" style={{ height: "48vh", overflowY: "scroll" }}>
        {props.trade.length > 0
          ? props.trade.map((i) => {
              return <TradeCard item={i} />;
            })
          : null}
      </div>
    </div>
  );
}
