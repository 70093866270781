import { View, Text } from "@react-pdf/renderer";
import React from "react";
import { Dark } from "../../../../../../Theme/Theme";
import moment from "moment";

export default function PdfCard(props) {
  const i = props.item;
  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderStyle:"solid",
        borderWidth:0,
        borderBottomWidth:1,
        borderBottomColor:Dark.text
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}> {i.remarks}</Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color:
              i.deposit == 0
                ? Dark.sell
                : i.withdraw == 0
                ? Dark.buy
                : Dark.buy,
          }}
        >
          {i.deposit == 0
            ? parseFloat(i.withdraw).toFixed(2)
            : i.withdraw == 0
            ? parseFloat(i.deposit).toFixed(2)
            : parseFloat(i.weekly_amt).toFixed(2)}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {" "}
          {moment(i.date_created).format("DD-MM-YYYY hh:mm:ss")}
        </Text>
      </View>
    </View>
  );
}
