import { View, Text } from "@react-pdf/renderer";
import React from "react";
import { Dark } from "../../../../../Theme/Theme";

export default function TotalCard(props) {
  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: Dark.sell,
            padding: 10,
            backgroundColor: Dark.background,
            borderRadius: 10,
          }}
        >
          LIMIT - {props.limit}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: Dark.primary,
            padding: 10,
            backgroundColor: Dark.background,
            borderRadius: 10,
          }}
        >
          PENDING - {props.total}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: Dark.sell,
            padding: 10,
            backgroundColor: Dark.background,
            borderRadius: 10,
          }}
        >
          STOP-LOSS - {props.sl}
        </Text>
      </View>
    </View>
  );
}
