import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Menu from "./Menu";
import HoldingCard from "./HoldingCard/HoldingCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import { Dialog } from "primereact/dialog";
import Client from "./Client/Client";
import Loading from "react-loading";
import TopCard from "./TopCard/TopCard";
import { useDispatch } from "react-redux";
const backend = new Backend();

export default function Holding() {
  const dispatch = useDispatch();
  const [key, setKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);

  const [name, setName] = useState("");

  const [holding, setHolding] = useState([]);
  const [holdingsearch, setHoldingSearch] = useState([]);
  const [client, setClient] = useState([]);

  const [total, setTotal] = useState(0);

  const [future, setFuture] = useState(0);
  const [futurebuy, setFutureBuy] = useState(0);
  const [futuresell, setFutureSell] = useState(0);

  const [mcx, setMcx] = useState(0);
  const [mcxbuy, setMcxBuy] = useState(0);
  const [mcxsell, setMcxSell] = useState(0);

  const [options, setOptions] = useState(0);
  const [optionsbuy, setOptionsBuy] = useState(0);
  const [optionssell, setOptionsSell] = useState(0);

  const [buy, setBuy] = useState(0);
  const [sell, setSell] = useState(0);

  useEffect(() => {
    loadHolding();
  }, []);

  const loadHolding = (a) => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_holding(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });
        setHolding(r.holding);
        setHoldingSearch(r.holding);

        setTotal(r.total);

        setFuture(r.future);
        setFutureBuy(r.futurebuy);
        setFutureSell(r.futuresell);

        setMcx(r.mcx);
        setMcxBuy(r.mcxbuy);
        setMcxSell(r.mcxsell);

        setOptions(r.options);
        setOptionsBuy(r.optionsbuy);
        setOptionsSell(r.optionssell);

        setBuy(r.buy);
        setSell(r.sell);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const Filter = (a) => {
    setReload(!reload);
    dispatch({
      type: "CLEAR_PF",
    });
    if (a == "TOTAL") {
      setHolding(holdingsearch);
    } else if (a == "FUTURE") {
      var data = holdingsearch.filter((x) => x.type == "fut");
      setHolding(data);
    } else if (a == "MCX") {
      var data = holdingsearch.filter((x) => x.type == "mcx");
      setHolding(data);
    } else if (a == "OPTIONS") {
      var data = holdingsearch.filter((x) => x.type == "fo");
      setHolding(data);
    } else {
      setHolding(holdingsearch);
    }
  };

  const sort = (s) => {
    setKey(!key);

    let data;

    if (s == "SymbolUp") {
      data = holdingsearch.sort((a, b) => (a.symbol > b.symbol ? 1 : -1));
    } else if (s == "SymbolDown") {
      data = holdingsearch.sort((a, b) => (a.symbol < b.symbol ? 1 : -1));
    } else if (s == "BuyQtyUp") {
      data = holdingsearch.sort((a, b) => (a.buyqty > b.buyqty ? 1 : -1));
    } else if (s == "BuyQtyDown") {
      data = holdingsearch.sort((a, b) => (a.buyqty < b.buyqty ? 1 : -1));
    } else if (s == "BuyAvgUp") {
      data = holdingsearch.sort((a, b) => (a.buyavg / a.symbolbuylen > b.buyavg / b.symbolbuylen ? 1 : -1));
    } else if (s == "BuyAvgDown") {
      data = holdingsearch.sort((a, b) => (a.buyavg / a.symbolbuylen < b.buyavg / b.symbolbuylen ? 1 : -1));
    } else if (s == "SellQtyUp") {
      data = holdingsearch.sort((a, b) => (a.sellqty > b.sellqty ? 1 : -1));
    } else if (s == "SellQtyDown") {
      data = holdingsearch.sort((a, b) => (a.sellqty < b.sellqty ? 1 : -1));
    } else if (s == "SellAvgUp") {
      data = holdingsearch.sort((a, b) => (a.sellavg / a.symbolbuylen > b.sellavg / b.symbolbuylen ? 1 : -1));
    } else if (s == "SellAvgDown") {
      data = holdingsearch.sort((a, b) => (a.sellavg / a.symbolbuylen < b.sellavg / b.symbolbuylen ? 1 : -1));
    } else if (s == "NetUp") {
      data = holdingsearch.sort((a, b) => (a.netqty > b.netqty ? 1 : -1));
    } else if (s == "NetDown") {
      data = holdingsearch.sort((a, b) => (a.netqty < b.netqty ? 1 : -1));
    } else if (s == "BQtyUp") {
      data = holdingsearch.sort((a, b) => (a.broker_sharing > b.broker_sharing ? 1 : -1));
    } else if (s == "BQtyDown") {
      data = holdingsearch.sort((a, b) => (a.broker_sharing < b.broker_sharing ? 1 : -1));
    } else {
      setHolding([]);
    }

    setHolding(data);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "82vh",
      }}
    >
      <div
        style={{ flex: 5, backgroundColor: Dark.background, height: "100%" }}
      >
        <TopCard
          holding={holding}
          Filter={Filter}
          future={future == null ? 0 : future}
          futurebuy={futurebuy == null ? 0 : futurebuy}
          futuresell={futuresell == null ? 0 : futuresell}
          mcx={mcx == null ? 0 : mcx}
          mcxbuy={mcxbuy == null ? 0 : mcxbuy}
          mcxsell={mcxsell == null ? 0 : mcxsell}
          options={options == null ? 0 : options}
          optionsbuy={optionsbuy == null ? 0 : optionsbuy}
          optionssell={optionssell == null ? 0 : optionssell}
          total={total == null ? 0 : total}
          buy={buy == null ? 0 : buy}
          sell={sell == null ? 0 : sell}
        />
        <Menu sort={sort} />
        <div
          key={[reload, key]}
          className="scroll"
          style={{ height: "66vh", overflowY: "scroll" }}
        >
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" color={Dark.text} height={35} width={35} />
            </div>
          ) : holding.length > 0 ? (
            holding.map((i, t) => {
              return (
                <HoldingCard
                  item={i}
                  index={t}
                  key={i.id}
                  total={holding.length}
                  show={() => setShow(true)}
                  name={(a, b) => setName("ALL STANDING OF " + a)}
                  client={(a) => setClient(a)}
                />
              );
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Holding Found.</div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        header={name}
        visible={show}
        className="modalpo"
        style={{ width: "60vw", height: "70vh" }}
        onHide={() => setShow(false)}
      >
        <Client client={client} />
      </Dialog>
    </div>
  );
}
