import { View, Text } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import moment from "moment";

export default function HoldingCard(props) {
  const item = props.item;
  const socket = props.socket;

  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [pf, setpf] = useState(0);

  var netnewqty, removeminus;

  netnewqty = parseFloat(item.buyqty) - parseFloat(item.sellqty);

  if (netnewqty > 0) {
    removeminus = netnewqty;
  } else {
    removeminus = netnewqty.toString().replace("-", "");
  }

  const sockettrade = (msg) => {
    if (msg != null) {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
        if (netnewqty > 0) {
          let Profitloss = 0;
          if (msg.Bid > 0) {
            Profitloss =
              parseFloat(
                parseFloat(msg.Bid) -
                  parseFloat(item.buyavg / item.symbolbuylen)
              ) * parseFloat(removeminus);
          } else {
            Profitloss = 0;
          }
          setpf(Profitloss);
        }
      } else {
        setbid(msg.LTP);
        if (netnewqty > 0) {
          let Profitloss = 0;
          if (msg.Bid > 0) {
            Profitloss =
              parseFloat(
                parseFloat(msg.LTP) -
                  parseFloat(item.buyavg / item.symbolbuylen)
              ) * parseFloat(removeminus);
          } else {
            Profitloss = 0;
          }
          setpf(Profitloss);
        }
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
        if (netnewqty < 0) {
          let Profitloss2 = 0;
          if (parseFloat(msg.Ask) > 0) {
            Profitloss2 =
              (item.sellavg / item.symbolselllen - msg.Ask) *
              parseFloat(removeminus);
          } else {
            Profitloss2 = 0;
          }
          setpf(Profitloss2);
        }
      } else {
        setask(msg.LTP);
        if (netnewqty > 0) {
          let Profitloss2 = 0;
          if (parseFloat(msg.LTP) > 0) {
            Profitloss2 =
              (item.sellavg / item.symbolselllen - msg.LTP) *
              parseFloat(removeminus);
          } else {
            Profitloss2 = 0;
          }
          setpf(Profitloss2);
        }
      }
    }
  };
  const socketbidask = (msg) => {
    if (msg != null) {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
        if (netnewqty > 0) {
          let Profitloss3 = 0;
          Profitloss3 =
            (msg.Bid - item.buyavg / item.symbolbuylen) *
            parseFloat(removeminus);
          setpf(Profitloss3);
        }
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
        if (netnewqty < 0) {
          let Profitloss4 = 0;
          Profitloss4 =
            (item.sellavg / item.symbolselllen - msg.Ask) *
            parseFloat(removeminus);
          setpf(Profitloss4);
        }
      }
    }
  };

  useEffect(() => {
    socket.emit("giverate", item.symbol_id);
    socket.on("trade" + item.symbol_id, sockettrade);
    socket.on("bidask" + item.symbol_id, socketbidask);
    return () => {
      socket.off("trade" + item.symbol_id, sockettrade);
      socket.off("bidask" + item.symbol_id, socketbidask);
    };
  }, []);

  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>{item.symbol} </Text>

        <Text style={{ color: Dark.text, fontSize: 6, paddingTop: 3 }}>
          {moment(item.expiry_date).format("DD-MMM")}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: Dark.text,
          }}
        >
          {parseFloat(item.buyqty).toFixed(0)}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 12, color: Dark.buy }}>
          {" "}
          {parseFloat(
            item.buyavg == 0 ? 0 : item.buyavg / item.symbolbuylen
          ).toFixed(0)}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 12, color: Dark.sell }}>
          {parseFloat(item.sellqty).toFixed(0)}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 12, color: Dark.sell }}>
          {parseFloat(
            item.sellavg == 0 ? 0 : item.sellavg / item.symbolselllen
          ).toFixed(0)}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: item.buyqty > 0 ? Dark.buy : Dark.sell,
            textAlign: "center",
          }}
        >
          {parseFloat(item.netqty).toFixed(0)}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 10, color: Dark.text }}>
          {parseFloat(
            item.broker_sharing == null ? 0 : item.broker_sharing
          ).toFixed(0)}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: Dark.text,
          }}
        >
          {item.netqty > 0 ? bid : ask}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 12, color: pf > 0 ? Dark.buy : Dark.sell }}>
          {parseFloat(pf).toFixed(0)}
        </Text>
      </View>
    </View>
  );
}
