import React from "react";
import { Dark } from "../../../../Theme/Theme";
import Status from "./Status";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toast";
export default function ClientCard(props) {
  const i = props.item;

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(`
      🙏 WELCOME TO 🙏 
      SAUDA
  😎CLIENT 》》
        
  🌐 SERVER 》》 ${i.server}
  🚹 USER NAME 》》 ${i.username}
  🚹 NAME 》》 ${i.name}
  🔐 PASSWORD 》》 ${"RAM007"}
  💰 CREDIT 》》 ${i.deposit_current}
  
  
  BROKRAGE 💰
  F & O : ${i.future_brokerage}
  MCX : ${i.mcx_brokerage}
  OPTIONS : ${i.options_brokerage}
  MARGIN : F : ${i.multiplier_future}, M :${i.multiplier_mcx}, O :${
        i.multiplier_options
      }
  Auto Square Off : ${i.auto_sq == 1 ? "On" : "Off"}
  🖥 FOR PC / IOS  : 
  
  Ios : https://client.sauda.io;
  Androit/Play Store : https://play.google.com/store/apps/details?id=com.saudapro&pcampaignid=web_share;
  Web/Desktop : https://client.sauda.io;
  
  CONTACT YOUR UPLINE
 IF YOU FACE ANY PROBLEM IN TRADING
      `);

      toast.success("Copied", {
        backgroundColor: Dark.buy,
        color: Dark.text,
      });
    } catch (err) {
      toast.error("Failed to copy!", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    }
  };

  return (
    <div
      onDoubleClick={copyToClipBoard}
      onClick={() => props.setClient(i)}
      style={{
        backgroundColor: Dark.secondary,
        color: "#fff",
        padding: 5,
        height: 120,
        marginBottom: 15,
        borderRadius: 10,
        display: "flex",
        borderStyle: "solid",
        borderColor: props.client.id == i.id ? Dark.primary : Dark.secondary,
      }}
    >
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{ paddingBottom: 5, fontSize: 15 }}>
          Server - {i.server}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 15 }}>
          Username - {i.username}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 15 }}>Name - {i.name}</div>
        <div style={{ paddingBottom: 5, fontSize: 12 }}>
          Balance :{" "}
          <NumericFormat
            value={i.deposit_current}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
        <div style={{ paddingBottom: 5, fontSize: 10 }}>
          Margin : F : {i.multiplier_future}, M : {i.multiplier_mcx}, O :{" "}
          {i.multiplier_options}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Future :{" "}
          <div style={{ color: i.future == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.future == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Mcx :{" "}
          <div style={{ color: i.mcx == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.mcx == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Options :{" "}
          <div style={{ color: i.options == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.options == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Fresh Limit :{" "}
          <div style={{ color: i.fresh_limit == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.fresh_limit == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Mid Limit :{" "}
          <div style={{ color: i.mid_limit == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.mid_limit == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div
          style={{
            paddingBottom: 5,
            fontSize: 12,
            color: Dark.primary,
          }}
        >
          <Status id={i.id} />
        </div>
      </div>
    </div>
  );
}
