import React from "react";
import { Dark } from "../../../../../Theme/Theme";

export default function Active(props) {
  return (
    <div
      key={props.status}
      style={{
        padding: 10,
        backgroundColor: props.status == 0 ? Dark.sell : Dark.buy,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 7,
        color: Dark.text,
      }}
    >
      Auto SqOff
    </div>
  );
}
