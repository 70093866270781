import React, { useContext, useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
import SocketContext from "../../../../Healer/SocketContect";
import { useDispatch } from "react-redux";

export default function HoldingCard(props) {
  const item = props.item;
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [pf, setpf] = useState(0);

  var netnewqty, removeminus;

  netnewqty = parseFloat(item.buyqty) - parseFloat(item.sellqty);

  if (netnewqty > 0) {
    removeminus = netnewqty;
  } else {
    removeminus = netnewqty.toString().replace("-", "");
  }

  const sockettrade = (msg) => {

    if (msg != null) {
      if (bid !== msg.Bid) {

        setbid(msg.Bid);
        if (netnewqty > 0) {
          let Profitloss = 0;
          if (msg.Bid > 0) {
            Profitloss =
              parseFloat(
                parseFloat(msg.Bid) -
                  parseFloat(item.buyavg / item.symbolbuylen)
              ) * parseFloat(removeminus);
          } else {
            Profitloss = 0;
          }
          setpf(Profitloss);
        }
      } else {
        setbid(msg.LTP);
        if (netnewqty > 0) {
          let Profitloss = 0;
          if (msg.LTP > 0) {

            Profitloss =
              parseFloat(
                parseFloat(msg.LTP) -
                  parseFloat(item.buyavg / item.symbolbuylen)
              ) * parseFloat(removeminus);

           
          } else {
            Profitloss = 0;
          }

     
          setpf(Profitloss);
        }
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
        if (netnewqty < 0) {
          let Profitloss2 = 0;
          if (parseFloat(msg.Ask) > 0) {
            Profitloss2 =
              (item.sellavg / item.symbolselllen - msg.Ask) *
              parseFloat(removeminus);
          } else {
            Profitloss2 = 0;
            
          }
          setpf(Profitloss2);
        }
      } else {
        setask(msg.LTP);

        if (netnewqty < 0) {
          let Profitloss2 = 0;
          if (parseFloat(msg.LTP) > 0) {
            Profitloss2 =
              (item.sellavg / item.symbolselllen - msg.LTP) *
              parseFloat(removeminus);
          } else {
            Profitloss2 = 0;
          }
 
          setpf(Profitloss2);
        }
      }
    }
  };
  const socketbidask = (msg) => {
    if (msg != null) {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
        if (netnewqty > 0) {
          let Profitloss3 = 0;
          Profitloss3 =
            (msg.Bid - item.buyavg / item.symbolbuylen) *
            parseFloat(removeminus);
          setpf(Profitloss3);
        }
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
        if (netnewqty < 0) {
          let Profitloss4 = 0;
          Profitloss4 =
            (item.sellavg / item.symbolselllen - msg.Ask) *
            parseFloat(removeminus);
          setpf(Profitloss4);
        }
      }
    }
  };

  useEffect(() => {
    socket.emit("giverate", item.symbol_id);
    socket.on("trade" + item.symbol_id, sockettrade);
    socket.on("bidask" + item.symbol_id, socketbidask);
    return () => {
      socket.off("trade" + item.symbol_id, sockettrade);
      socket.off("bidask" + item.symbol_id, socketbidask);
    };
  }, []);


  dispatch({
    type: "PF",
    id: item.symbol_id,
    pf: pf,
    total: props.total,
  });
  return (
    <div
      key={item.symbol_id}
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 14 }}>{item.symbol}</div>
        <div style={{ paddingTop: 5, fontSize: 10 }}>
          {moment(item.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          color: Dark.buy,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(item.buyqty).toFixed(0)}
      </div>
      <div
        style={{
          color: Dark.buy,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(
          item.buyavg == 0 ? 0 : item.buyavg / item.symbolbuylen
        ).toFixed(2)}
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(item.sellqty).toFixed(0)}
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(
          item.sellavg == 0 ? 0 : item.sellavg / item.symbolselllen
        ).toFixed(2)}
      </div>
      <div
        style={{
          color: netnewqty > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(netnewqty).toFixed(0)}
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(
          item.broker_sharing == null ? 0 : item.broker_sharing
        ).toFixed(0)}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 16,
        }}
      >
        {item.netqty > 0 ? bid : ask}
      </div>
      <div
        style={{
          color: pf > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(pf).toFixed(0)}
      </div>

      <div
        onClick={() => {
          props.name(item.symbol);
          props.client(item.standing);
          props.show();
        }}
        style={{
          color: Dark.primary,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 16,
        }}
      >
        Client
      </div>
    </div>
  );
}
