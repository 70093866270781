import React, { useEffect, useState } from "react";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../../../../Theme/Theme";
import Loading from "react-loading";
import SymbolCard from "./SymbolCard";
const backend = new Backend();
export default function SymbolBrokerage(props) {
  const client_id = props.client_id;
  const [loading, setLoading] = useState(false);
  const [symbol, setSymbol] = useState([]);

  useEffect(() => {
    loadSymbol();
  }, []);

  const loadSymbol = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client_id,
    };

    backend.symbol_brokerage(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setSymbol(r.symbol);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="scroll" style={{ height: "47vh", overflowY: "scroll" }}>
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Loading type="spin" color={Dark.text} height={30} width={30} />
          </div>
        ) : symbol.length > 0 ? (
          symbol.map((item, index) => {
            return (
              <SymbolCard
                item={item}
                loadSymbol={loadSymbol}
                client_id={client_id}
              />
            );
          })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Symbol Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}
